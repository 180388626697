import type { Control } from 'react-hook-form/dist/types/form';
import { useController } from 'react-hook-form';
import { useRootStore } from 'app/mobxStore';
import React, { useEffect } from 'react';
import { COMMENT_FORM_FIELDS_ENUM, type ICommentFormData } from './types';
import { useTranslation } from 'react-i18next';
import RichTextField from 'app/components/richTextField/RichTextField';

interface IProps {
  control: Control<ICommentFormData>;
  isReadOnly?: boolean;
}

const CommentTitle = (props: IProps): React.JSX.Element => {
  const { control } = props;
  const { caseStore } = useRootStore();
  const { field } = useController({
    name: COMMENT_FORM_FIELDS_ENUM.COMMENT,
    control,
    rules: {
      required: true
    }
  });
  const { t } = useTranslation();
  const mentionOptions = caseStore.openedCase?.basicCase.data.caseFollowers;

  useEffect(() => {
    if (caseStore.tempComment !== '' && caseStore.tempComment !== field.value) {
      field.onChange(caseStore.tempComment);
    }
  }, [caseStore.tempComment]);

  return (
    <RichTextField
      onChange={(value, rawValue: string): void => {
        caseStore.setTempComment(value);
        caseStore.setTempRawComment(rawValue);
        field.onChange(value);
      }}
      value={caseStore.tempComment}
      placeholderText={t('askAQuestion').toString()}
      mentionOptions={mentionOptions}
    />
  );
};

export default CommentTitle;
