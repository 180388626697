import React, { useState, useEffect, useCallback } from 'react';
import { useRootStore } from 'app/mobxStore';
import SurgeonSelect from 'app/components/fields/SurgeonSelect';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import HuddleTabs from './HuddleTabs';
import FollowersSelect from 'app/components/fields/FollowersSelect';
import CaseStatusChip from 'app/components/caseStatusChip/CaseStatusChip';
import CaseDate from 'app/components/fields/CaseDate';
import { FORM_FIELDS_ENUM, SERVER_FORM_FIELDS_ENUM } from 'app/components/fields/types';
import CaseTitle from '../fields/CaseTitle';
import { isDateInPast } from '../../mobxStore/caseStoreHelper';
import './FillCaseMetaForm.scss';
import { lite } from '../../services/apiService/apiService';
import type {
  ICase,
  IUserDataWithRoles,
  IUserSelectOption,
  ISelectOption
} from '../../mobxStore/types';
import { parseUserToSelectOption } from '../../mobxStore/MetaDataStore';
import { useTranslation } from 'react-i18next';
import { ROLES } from '../../consts';
import CaseRoom from '../fields/CaseRoom';
import Button from 'app/components/buttons/Button';
import PencilIcon from 'app/assets/icons/Pencil_icon';
import CasePatientName from '../fields/CasePatientName';
import CasePatientNumber from '../fields/CasePatientNumber';
import { useFillMetaForm } from 'app/components/fillMetaForm/useFillMetaForm';
import { useDebounce } from '../../../@fuse/hooks';
import _ from 'lodash';

const DEBOUNCE_MSEC = 500;

interface IProps {
  handleClose: () => Promise<void>;
}

const parseSurgeonsOptions = (
  surgeonsOptions: IUserSelectOption[],
  openedCase: ICase
): IUserSelectOption[] => {
  const isAttendingNotActive = openedCase.data.attending?.isActive === false;
  if (!isAttendingNotActive) {
    return surgeonsOptions;
  }
  const attending = openedCase.data.attending as IUserDataWithRoles;
  return [parseUserToSelectOption(attending), ...surgeonsOptions];
};

const FormHeader = (props: IProps): React.JSX.Element => {
  const { handleClose } = props;
  const isCommentsHeader = false;
  const { caseStore, metaDataStore, liteNonSurgeon, userStore } = useRootStore();
  const openedCase = caseStore.openedCase;
  const { t } = useTranslation();
  const [isTitleEditable, setIsTitleEditable] = useState(true);
  const [dateToPostponeCase, setDateToPostponeCase] = useState<Date>();
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [curSelectedFollowers, setCurSelectedFollowers] = useState<ISelectOption[]>([]);

  const {
    control: metaFormControl,
    formState: metaFormState,
    allValues: metaFormAllValues,
    handleMetaDataChange
  } = useFillMetaForm();

  const isFieldDirty = (field: FORM_FIELDS_ENUM): boolean => {
    return metaFormState.dirtyFields[field] !== undefined;
  };

  const debounceFunction = useCallback(
    async (field: SERVER_FORM_FIELDS_ENUM, value: string | Date) => {
      await handleMetaDataChange(field, value);
    },
    []
  );

  const debounceUpdate = useDebounce(debounceFunction, DEBOUNCE_MSEC, {
    leading: false,
    trailing: true
  }).debounceFunc;

  const onMetaChangeSelect = (
    formField: FORM_FIELDS_ENUM,
    serverField: SERVER_FORM_FIELDS_ENUM,
    selectedOption: ISelectOption | undefined
  ): void => {
    if (!isFieldDirty(formField)) {
      return;
    }
    if (selectedOption === undefined) {
      return;
    }
    void debounceUpdate(serverField, selectedOption.value);
  };
  const onMetaChangeValue = (
    formField: FORM_FIELDS_ENUM,
    serverField: SERVER_FORM_FIELDS_ENUM,
    value: string | Date
  ): void => {
    if (!isFieldDirty(formField)) {
      return;
    }
    void debounceUpdate(serverField, value);
  };

  // unused expand functionality
  // const [isExpanded, setIsExpanded] = useState(!isCommentsHeader);
  const isExpanded = true;

  if (openedCase === null) {
    return <></>;
  }

  const selectedFollowers = metaFormAllValues[FORM_FIELDS_ENUM.FOLLOWERS];
  useEffect(() => {
    if (isInitialLoad) {
      setIsInitialLoad(false);
      setCurSelectedFollowers(selectedFollowers);
      return;
    }
    if (_.isEqual(selectedFollowers, curSelectedFollowers)) {
      return;
    }
    setCurSelectedFollowers(selectedFollowers);
    void openedCase.basicCase.setFollowersToServer(
      selectedFollowers.map(follower => ({
        caseId: openedCase.basicCase.data.id,
        userId: follower.value
      }))
    );
    openedCase.basicCase.updateFollowersInStore(selectedFollowers.map(follower => follower.value));
  }, [selectedFollowers]);

  const selectedService = metaFormAllValues[FORM_FIELDS_ENUM.SERVICE];
  useEffect(() => {
    onMetaChangeSelect(FORM_FIELDS_ENUM.SERVICE, SERVER_FORM_FIELDS_ENUM.SERVICE, selectedService);
  }, [selectedService]);

  const selectedSurgeon = metaFormAllValues[FORM_FIELDS_ENUM.SURGEON];
  useEffect(() => {
    onMetaChangeSelect(FORM_FIELDS_ENUM.SURGEON, SERVER_FORM_FIELDS_ENUM.SURGEON, selectedSurgeon);
  }, [selectedSurgeon]);

  const selectedDate = metaFormAllValues[FORM_FIELDS_ENUM.DATE];
  useEffect(() => {
    onMetaChangeValue(FORM_FIELDS_ENUM.DATE, SERVER_FORM_FIELDS_ENUM.DATE, selectedDate);
  }, [selectedDate]);

  const title = metaFormAllValues[FORM_FIELDS_ENUM.TITLE];
  useEffect(() => {
    onMetaChangeValue(FORM_FIELDS_ENUM.TITLE, SERVER_FORM_FIELDS_ENUM.TITLE, title);
  }, [title]);

  const description = metaFormAllValues[FORM_FIELDS_ENUM.DESCRIPTION];
  useEffect(() => {
    onMetaChangeValue(
      FORM_FIELDS_ENUM.DESCRIPTION,
      SERVER_FORM_FIELDS_ENUM.DESCRIPTION,
      description
    );
  }, [description]);

  const room = metaFormAllValues[FORM_FIELDS_ENUM.ROOM];
  useEffect(() => {
    onMetaChangeValue(FORM_FIELDS_ENUM.ROOM, SERVER_FORM_FIELDS_ENUM.ROOM, room);
    caseStore.openedCase?.basicCase.setRoomToStore(room);
  }, [room]);

  const patientInitials = metaFormAllValues[FORM_FIELDS_ENUM.PATIENT_INITIALS];
  useEffect(() => {
    onMetaChangeValue(
      FORM_FIELDS_ENUM.PATIENT_INITIALS,
      SERVER_FORM_FIELDS_ENUM.PATIENT_INITIALS,
      patientInitials
    );
    caseStore.openedCase?.basicCase.setPatientInitialsToStore(patientInitials);
  }, [patientInitials]);

  const mrn = metaFormAllValues[FORM_FIELDS_ENUM.MRN];
  useEffect(() => {
    onMetaChangeValue(FORM_FIELDS_ENUM.MRN, SERVER_FORM_FIELDS_ENUM.MRN, mrn);
    caseStore.openedCase?.basicCase.setMrnToStore(mrn);
  }, [mrn]);

  const siteId = metaFormAllValues[FORM_FIELDS_ENUM.SERVICE]?.value;
  const surgeonsOptions = siteId
    ? metaDataStore.getSurgeonsOptionsBySite(siteId)
    : metaDataStore.surgeonsOptions;

  const getCareTeamOptions = (): IUserSelectOption[] => {
    const careTeamOptions = siteId
      ? metaDataStore.getCareTeamOptionsBySite(siteId)
      : metaDataStore.careTeamOptions;
    if (lite) {
      return careTeamOptions.filter(
        option => option.roles.find(r => r.role === ROLES.ATTENDING) === undefined
      );
    }
    return careTeamOptions;
  };

  const parsedSurgeonsOptions = parseSurgeonsOptions(surgeonsOptions, openedCase.basicCase);
  const isFollowersSelectReadyOnly = isDateInPast(
    openedCase.basicCase.data.caseDate,
    openedCase.basicCase.store.rootStore.tz
  );

  const handleToggleEditableTitle = (): void => {
    setIsTitleEditable(!isTitleEditable);
  };

  const getPatientInfo = (): React.JSX.Element | null => {
    const initials = openedCase.basicCase.data.patientInitials;
    const mrn = openedCase.basicCase.data.mrn;

    if (!initials && !mrn) {
      return null;
    }

    return (
      <div className="patient-info">
        {initials && <div className="initials">{initials}</div>}
        {mrn && <div className="number">{mrn}</div>}
      </div>
    );
  };

  const indexInDayForAttending = caseStore.getCaseIndexInDayForAttending(openedCase.basicCase.data);
  const showPatientInfoChips =
    !lite && !userStore.loggedInUser.isVendor && userStore.loggedInUser.data.isNurseLeader;
  const showPatientInfoSubtitle =
    !lite && !userStore.loggedInUser.isVendor && !userStore.loggedInUser.data.isNurseLeader;

  return (
    <div className={clsx('form-header', { expanded: isExpanded })}>
      <HuddleTabs
        handleClose={handleClose}
        dateToPostponeCase={dateToPostponeCase}
        setDateToPostponeCase={setDateToPostponeCase}
      />
      <div
        className="title-container"
        onBlur={() => {
          if (isCommentsHeader && isTitleEditable) {
            setTimeout(() => {
              handleToggleEditableTitle();
            });
          }
        }}
      >
        {isTitleEditable ? (
          <CaseTitle control={metaFormControl} isFocusOnInit={isCommentsHeader} />
        ) : (
          <div
            className="title-read-only-container"
            onClick={() => {
              handleToggleEditableTitle();
            }}
          >
            <div className="title-read-only">{openedCase.basicCase.data.displayId}</div>
            <Button
              classNames="btn circle-button edit-title-button"
              startIcon={<PencilIcon />}
              disableTouchRipple
            />
          </div>
        )}
      </div>
      {showPatientInfoSubtitle && getPatientInfo()}
      <div className="selects">
        <div className="selects-row">
          <SurgeonSelect
            control={metaFormControl}
            options={parsedSurgeonsOptions}
            allValues={metaFormAllValues}
            isReadOnly={lite}
          />
          <div className="case-info-chip">{`${t('caseNumber')}${indexInDayForAttending}`}</div>
          <CaseRoom control={metaFormControl} />
          {showPatientInfoChips && (
            <>
              <CasePatientName control={metaFormControl} />
              <CasePatientNumber control={metaFormControl} />
            </>
          )}
          {!liteNonSurgeon && (
            <CaseDate
              control={metaFormControl}
              allValues={metaFormAllValues}
              setDateToPostponeCase={setDateToPostponeCase}
              isReadOnly={liteNonSurgeon}
            />
          )}
        </div>
      </div>
      {isExpanded && (
        <FollowersSelect
          control={metaFormControl}
          options={getCareTeamOptions()}
          isReadOnly={isFollowersSelectReadyOnly}
        />
      )}
      {openedCase && <CaseStatusChip case={openedCase.basicCase} />}
    </div>
  );
};

export default observer(FormHeader);
