import type { SuggestionOptions, SuggestionProps } from '@tiptap/suggestion';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import type { MentionSuggestion } from './MentionSuggestionOptions';
import { parseUserToSelectOption } from 'app/mobxStore/MetaDataStore';
import UserAvatar from 'app/components/userAvatar/UserAvatar';
import type { IUserDataWithRoles } from 'app/mobxStore/types';

export interface SuggestionListRef {
  onKeyDown: NonNullable<
    ReturnType<NonNullable<SuggestionOptions<MentionSuggestion>['render']>>['onKeyDown']
  >;
}

interface MentionNodeAttrs {
  id: string | null;
  label?: string | null;
  user?: IUserDataWithRoles;
}

export type SuggestionListProps = SuggestionProps<MentionSuggestion>;

const SuggestionList = forwardRef<SuggestionListRef, SuggestionListProps>((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = (index: number): void => {
    if (index >= props.items.length) {
      return;
    }

    const suggestion = props.items[index];

    const mentionItem: MentionNodeAttrs = {
      id: suggestion.id,
      label: suggestion.mentionLabel,
      user: suggestion.user
    };
    props.command(mentionItem);
  };

  const upHandler = (): void => {
    setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length);
  };

  const downHandler = (): void => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = (): void => {
    selectItem(selectedIndex);
  };

  useEffect(() => {
    setSelectedIndex(0);
  }, [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        enterHandler();
        return true;
      }

      return false;
    }
  }));

  return (
    <div className="mention-list">
      {props.items.length ? (
        props.items.map((item, index) => (
          <button
            className={index === selectedIndex ? 'is-selected' : ''}
            key={index}
            onClick={() => {
              selectItem(index);
            }}
          >
            <UserAvatar user={parseUserToSelectOption(item.user)} size={'small'} />
            {item.mentionLabel}
          </button>
        ))
      ) : (
        <div className="item">No result</div>
      )}
    </div>
  );
});

SuggestionList.displayName = 'SuggestionList';

export default SuggestionList;
