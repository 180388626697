import type { IAllFormData, IFormData } from 'app/components/fields/types';
import type { CASE_STATE, ROLES, USER_CASE_ROLES } from 'app/consts';
import type { Subscription } from 'zen-observable-ts';

export interface IUserStore {
  rootStore: IRootStore;
  loggedInUser: ILoggedInUser;
  resetUser: () => void;
  inviteUser: (newSite: boolean) => Promise<string>;
  inviteId: string | null;
  setInviteId: (inviteId: string | null) => void;
}

export interface ICareTeamDefaultsStore {
  rootStore: IRootStore;
  defaults: ICareTeamDefaults[];
  setDefaults: (defaults: ICareTeamDefaults[]) => void;
  loadDefaults: () => Promise<void>;
  hasDefault: (userId: string, procedureId: string) => boolean;
  getDefault: (userId: string, procedureId: string) => ICareTeamDefaults;
  saveDefault: (
    userId: string,
    procedureId: string,
    fields: IDynamicField[],
    values: IDynamicFieldValues,
    fieldsOrder: string[]
  ) => Promise<void>;
  saveRawDefault: (
    userId: string,
    procedureId: string,
    rawDefault: ICareTeamDefaults
  ) => Promise<void>;
  handleFieldRecommendation: (suggestTemplate: IFieldRecommendation, ok: boolean) => Promise<void>;
}

export interface IMetaDataStore {
  rootStore: IRootStore;
  sites: ISiteData[];
  users: IFullUserData[];
  activeUsers: IFullUserData[];
  procedures: IProcedureDataWithCount[];
  loading: boolean;
  curOntVersion: string;
  loaded: boolean;
  metaLoaded: boolean;
  uploadcareKey: string;
  isSaml: boolean;
  flatfile: boolean;
  tz: string;
  loadUserTemplates: () => Promise<void>;
  setEnum: (sitesIds: string, roles: ROLES[]) => Promise<void>;
  loadUsersList: (logError?: boolean) => Promise<void>;
  setSites: (sites: ISiteData[]) => void;
  setUsers: (users: IFullUserData[]) => void;
  setProcedures: (procedures: IProcedureDataWithCount[]) => void;
  setLoading: (loading: boolean) => void;
  setCurOntVersion: (curOntVersion: string) => void;
  setLoaded: (loaded: boolean) => void;
  setMetaLoaded: (metaLoaded: boolean) => void;
  setUploadcareKey: (uploadcareKey: string) => void;
  setIsSaml: (isSaml: boolean) => void;
  setFlatfile: (flatfile: boolean) => void;
  initTz: (tz: string) => void;
  setTz: (tz: string) => void;
  reset: () => void;
  surgeonsOptions: IUserSelectOption[];
  careTeamOptions: IUserSelectOption[];
  servicesOptions: ISelectOption[];
  proceduresOptions: (
    siteId: string | undefined,
    userId: string | undefined
  ) => IProcedureSelectOption[];
  getSurgeonsOptionsBySite: (siteId: string) => IUserSelectOption[];
  getCareTeamOptionsBySite: (siteId: string) => IUserSelectOption[];
  setPushOptIn: (pushOptIn: boolean) => void;
  followAllUsers: (siteId: string) => IFullUserData[];
  isSameTimezone: boolean;
  formatDateInSiteTZ: (date: Date, format: string) => string;
  updateCurrentUserRole: (oldRole: ROLES, newRole: ROLES) => void;
  getVendorUsers: (siteId: string, vendor: string) => IFullUserData[];
}

export interface ICaseStore {
  casesLoadingDone: boolean;
  setCasesLoadingDone: (done: boolean) => void;
  selectedTabInHuddle: TabsEnum;
  setSelectedTab: (tab: TabsEnum) => void;
  tempRawComment: string;
  setTempRawComment: (comment: string) => void;
  tempComment: string;
  setTempComment: (comment: string) => void;
  savingStatusInHuddle: SavingStatus;
  setSavingStatus: (status: SavingStatus) => void;
  setIsSaving: (isSaving: boolean) => void;
  minDate: Date;
  rootStore: IRootStore;
  items: ICase[];
  lastCreatedCase: ICase | null;
  recentlyHuddledCase: ICase | null;
  caseToOpenNext: ICase | null;
  recentlyCreatedCaseId: string | null;
  setCases: (cases: ICaseBasicData[]) => void;
  addCase: (caseData: ICase) => void;
  isShowAddonPanel: boolean;
  setIsShowAddonPanel: (isShow: boolean) => void;
  setLastCreatedCase: (caseToSet: ICase | null) => void;
  setRecentlyHuddledCase: (caseToSet: ICase | null) => void;
  setCaseToOpenNext: (caseToSet: ICase | null) => void;
  setRecentlyCreatedCaseId: (caseId: string | null) => void;
  updateCase: (caseData: ICase) => void;
  loadMyCases: (logError?: boolean) => Promise<void>;
  loadAllCases: (short: boolean, logError?: boolean) => Promise<void>;
  lastUpdate: number;
  setLastUpdate: (lastUpdate: number) => void;
  checkSubscription: () => void;
  subscribeToCases: () => Promise<void>;
  unSubscribeFromCases: () => void;
  subscription: Subscription | null;
  refreshForm: boolean;
  refreshSubscriptions: boolean;
  setRefreshSubscriptions: (refresh: boolean) => void;

  checkSubscriptionInterval: NodeJS.Timeout;
  setRefreshForm: (refreshForm: boolean) => void;
  isMyCases: boolean;
  casesFilter: ICasesFilter;
  isHuddleDialogOpen: boolean;
  setIsHuddleDialogOpen: (isOpen: boolean) => void;
  isEditTemplateDialogOpen: boolean;
  setIsEditTemplateDialogOpen: (isOpen: boolean) => void;
  isMyAccountDialogOpen: boolean;
  setIsMyAccountDialogOpen: (isOpen: boolean) => void;
  isInviteDialogOpen: boolean;
  setIsInviteDialogOpen: (isOpen: boolean) => void;
  isNewSiteInvite: boolean;
  setIsNewSiteInvite: (isNewSite: boolean) => void;
  isSettingsDialogOpen: boolean;
  setIsSettingsDialogOpen: (isOpen: boolean) => void;
  createCase: (data: IFormData, ontologyVersion: string) => Promise<ICase>;
  fillCaseMetaDataWithOutProcedure: (caseId: string, data: IAllFormData) => Promise<ICase>;
  updateCaseProcedureToServer: (
    caseId: string,
    procedure: IProcedureSelectOption,
    ontologyVersion: string
  ) => Promise<IFullCase>;
  updateCaseIndexInDay: (caseId: string, indexInDay: number) => Promise<void>;
  checkForFormChange: (curProcedure: IProcedure, newProcedure: IProcedure) => boolean;
  getCaseMetaData: (procedureId: string, isUserTemplate: boolean) => Promise<IProcedure>;
  getCaseMetaDataFromS3: (procedureId: string) => Promise<IProcedure>;
  getCaseMetaDataFromDB: (procedureId: string) => Promise<IProcedure>;

  setIsMyCases: (isMyCases: boolean) => void;
  setCasesFilter: (filter: ICasesFilter) => void;
  selectedDateFilter: Date;
  setSelectedDateFilter: (date: Date) => void;
  selectedStatusFilter: CaseStatusEnum;
  setSelectedStatusFilter: (status: CaseStatusEnum) => void;
  setOpenedCase: (openedCase: IFullCase | null) => void;

  openedCase: IFullCase | null;
  openCase: (id: string) => Promise<ICaseFullData | null>;
  isNewCase: boolean;
  setHuddleScrolledToBottom: (isBottom: boolean) => void;
  huddleScrolledToBottom: boolean;
  setHuddleScrolled: (scrollTopValue: boolean) => void;
  huddleScrolled: boolean;
  setIsAutoScrolling: (isAuto: boolean) => void;
  setCaseToShowUnfollowTooltip: (caseId: string | null) => void;
  caseToShowUnfollowTooltip: string | null;
  setRemoveChipsMode: (isRemoveChipsMode: boolean) => void;
  isRemoveChipsMode: boolean;
  isAutoScrolling: boolean;
  setLastHuddleScrollPosition: (scrollTopValue: number) => void;
  lastHuddleScrollPosition: number;
  setIsNewCase: (isNewCase: boolean) => void;
  myCases: ICase[];
  filteredCasesByDate: Map<string, ICase[]>;
  filteredCases: (isCatchUp: boolean) => ICase[];
  sortedCases: ICase[];
  hasCaseInDateByFilter: (date: Date) => boolean;
  hasUnreadMessagesFilteredCasesInDay: (date: Date) => boolean;
  hasNeedAttentionFilteredCasesInDay: (date: Date) => boolean;
  hasNeedAttentionUpcoming: () => boolean;
  hasNeedAttentionCurrent: () => boolean;
  hasCaseInDateCurrentUser: (date: Date) => boolean;
  hasCaseInStatus: (status: CaseStatusEnum) => boolean;
  needFillHuddleMeta: boolean;
  getLocalStorageData: () => Map<string, string>;
  getIsMyCasesSavedPref: () => boolean;
  hasIsMyCasesSavedPref: () => boolean;
  getUploadcareSignature: () => Promise<IUploadcareSignature>;
  clearCaseData: (caseId: string) => Promise<void>;
  huddleRate: number;
  updateCaseState: (caseId: string, state: CASE_STATE) => void;
  updateCaseIntraopText: (caseId: string, intraopText: string) => void;
  updateCaseIsReady: (caseId: string, isReady: boolean) => void;
  refreshFieldValuesCountSum: (fvc: IFieldValuesCountSum) => void;
  getFieldValuesCount: (fieldId: string) => IGetFieldValuesCount;
  isUserFollowedCaseInPast: boolean;
  unreadCommentsSubscription: Subscription | null;
  setUnreadCommentsMap: (map: Map<string, number>) => void;
  setCurrentUserPrevLastSeen: (lastSeen: Date | null) => void;
  unreadCommentsMap: Map<string, number>;
  currentUserPrevLastSeen: Date | null;
  getCaseIndexInDayForAttending: (caseData: ICaseBasicData) => number;
}

export interface IUploadcareSignature {
  signature: string;
  expire: number;
}

export interface IRootStore {
  userStore: IUserStore;
  caseStore: ICaseStore;
  metaDataStore: IMetaDataStore;
  careTeamDefaultsStore: ICareTeamDefaultsStore;
  isLoggedInUserAttending: boolean;
  isLoggedInUserId: (id: string) => boolean;
  findUserById: (id: string) => IFullUserData | undefined;
  userRoleInCase: USER_CASE_ROLES;
  needsBriefApproval: boolean;
  canUpdate: boolean;
  showEditMode: boolean;
  hasLock: boolean;
  liteNonSurgeon: boolean;
  liteSurgeon: boolean;
  showCasesToggle: boolean;
  tz: string;
  curEditMode: boolean;
  setCurEditMode: (curEditMode: boolean) => void;
  pushSupported: boolean;
  isPushOptIn: boolean;
  setIsPushOptIn: (isPushOptIn: boolean) => void;
}

export interface IStoreData {
  rootStore: IRootStore;
}

export interface IFullCase {
  basicCase: ICase;
  fieldsOrder: string[];
  setFieldsOrder: (fieldsOrder: string[]) => void;
  referencedData: ICaseReferencedData;
  setReferencedData: (data: ICaseReferencedData) => void;
  setProcedure: (procedure: IProcedure) => void;
  deletePlanFeedbackFromServer: () => Promise<void>;
  deletePlanFeedbackFromStore: () => void;
  upsertPlanFeedbackToServer: (rating: number, text: string) => Promise<void>;
  upsertPlanFeedbackToStore: (rating: number, text: string) => void;
  updatePlanFeedback: (rating: number, text: string) => void;
  getCurrentUserPlanFeedback: () => IPlanFeedbackData;
  upsertUserFieldOptionsToServer: (fieldId: string, values: string[]) => Promise<void>;
  updateUserFieldOptionsToStore: (fieldId: string, values: string[]) => void;
  updateFieldValuesEditsToStore: (fieldId: string, value: IFieldValuesEdits) => void;
  addedFields: IDynamicField[];
  addField: (field: IDynamicField) => void;
  clearFields: () => void;
  removedFields: string[];
  removeField: (field: IDynamicField) => void;
  upsertUserFormFieldsToServer: (objects: IServerProcessedUserFormField[]) => Promise<void>;
  upsertUserRemovedFormFieldsToServer: (
    objects: IServerProcessedUserRemovedFormField[]
  ) => Promise<void>;
}

export interface ICase {
  isResetting: boolean;
  setIsResetting: (isReset: boolean) => void;
  isOpenQuietHoursPanel: boolean;
  setIsOpenQuietHoursPanel: (isOpen: boolean) => void;
  id: string;
  store: ICaseStore;
  data: ICaseBasicData;
  commentsStore: ICaseCommentsStore;
  updateCaseMetaDataToServer: (values: Record<string, any>) => Promise<void>;
  handleDateChange: (caseDate: Date) => Promise<void>;
  setIndexInDayToStore: (index: number) => void;
  setFollowersToServer: (followers: ICaseFollowersInput[]) => Promise<void>;
  upsertFollowerToServer: (userId: string) => Promise<void>;
  updateLastSeenToServer: (now: Date) => Promise<void>;
  removeFollowerFromServer: (userId: string) => Promise<void>;
  updateFollowersInStore: (userId: string[]) => void;
  upsertFollowerWithLastSeenToStore: (userId: string, lastSeen: Date | null) => void;
  removeFollowerFromStore: (userId: string) => void;
  setStandardProcedureToServer: (standardProcedure: boolean) => Promise<void>;
  setStandardProcedureToStore: (standardProcedure: boolean) => void;
  setIsAddonToServer: (isAddon: boolean) => Promise<void>;
  setIsAddonToStore: (isAddon: boolean) => void;
  deleteCase: () => Promise<void>;
  isDeleted: boolean;
  cancelCase: () => Promise<void>;

  assets: ICaseAsset[];
  setAssets: (assets: ICaseAsset[]) => void;
  loadAssets: () => Promise<void>;
  deleteAsset: (assetId: string) => Promise<void>;
  isToday: boolean;
  fullImages: string[];
  thumbImages: string[];

  setTitle: (title: string) => void;
  addAsset: (uuid: string) => Promise<ICaseAsset>;
  updateAssetMeta: (id: string, uuid: string, meta: IAssetMeta) => Promise<ICaseAsset>;
  updateAssetDescription: (id: string, uuid: string, description: string) => Promise<ICaseAsset>;
  setData: (data: ICaseBasicData) => void;
  setDate: (date: Date) => void;
  setState: (state: CASE_STATE) => void;
  needsAttention: (lastSeen: Date | null, catchUp: boolean) => boolean;
  getUserLastSeen: (userId: string) => Date | null;
  getCurrentUserLastSeen: () => Date | null;
  isInPast: () => boolean;
  setIsReady: (isReady: boolean) => void;
  setIntraopText: (intraopText: string) => void;
  setRoomToStore: (room: string) => void;
  setRoomToServer: (room: string) => Promise<void>;
  setPatientInitialsToStore: (patientInitials: string) => void;
  setPatientInitialsToServer: (patientInitials: string) => Promise<void>;
  setMrnToStore: (mrn: string) => void;
  setMrnToServer: (mrn: string) => Promise<void>;
  updateCaseEditLock: (isLocked: boolean) => void;
}

export interface ICaseCommentsStore {
  kase: ICase;

  commentsSubscription: null | Subscription;
  addComment: (comment: string, rawComment: string) => Promise<void>;
  removeComment: (comment: ICommentData) => void;
  subscribeToComments: () => void;
  unsubscribeFromComments: () => void;
  setComments: (comments: ICommentData[]) => void;
  getCommentsUnreadCount: () => number;
  commentsMarkAllAsRead: () => Promise<void>;
  commentLike: (commentId: string, like: string) => Promise<void>;
  lastReadCommentId: string;
}

export interface ILoggedInUser {
  loggedIn: boolean;
  data: ILoggedInUserData;
  wrongPasswordError: boolean;
  setData: (data: ILoggedInUserData) => void;
  setLoggedIn: (loggedIn: boolean) => void;
  setRoles: (roles: ROLES[]) => void;
  setWrongPasswordError: (wrongPasswordError: boolean) => void;
  isRole: (role: ROLES) => boolean;
  isAttending: boolean;
  isInviter: boolean;
  isVendor: boolean;
}

export interface ILoggedInUserData extends IUserDataWithRoles {
  followAll: boolean;
  isNurseLeader: boolean;
  phone: string;
  email: string;
  reminderDays: string[] | null;
  onboardingDone: boolean;
}

export interface ICommentData {
  id: string;
  comment: string;
  formattedComment: string;
  user: IUserDataWithRoles;
  createdAt: string;
  updatedAt: string;
  metaData: ICommentMetaData[];
}

export interface ICommentMetaData {
  createdById: string;
  user: IUserDataWithRoles;
  readAt?: Date | null;
  reaction?: string | null;
}

export interface ISiteRawData {
  id: string;
  name: string;
  specialties: string;
  timezone: string;
}

export interface ISiteData {
  id: string;
  name: string;
  specialties: string[];
  timezone: string;
}

export interface ICaseStateLogData {
  fromState: string;
  toState: string;
  createdById: string;
  createdAt: string;
}

export interface IUserDataWithRoles {
  id: string;
  nickName: string;
  firstName: string;
  lastName: string;
  avatar: string;
  roles: IRoleData[];
  isActive: boolean;
  vendor: string;
  isNurseLeader: boolean;
}

export interface IFullUserData extends IUserDataWithRoles {
  userSites: IUserSiteData[];
  followAll: boolean;
}

export interface IUserMetaData {
  phone: string;
  email: string;
  reminderDays: string[];
  onboardingDone: boolean;
}

export interface IUserSiteData {
  siteId: string;
}

export interface ICaseFollowerData {
  userId: string;
  user: IUserDataWithRoles;
  lastSeen: Date | null;
}

export interface IRoleData {
  role: ROLES;
}

export interface ICaseFullData extends ICaseBasicData, ICaseReferencedData {}

//
// export interface ICaseData extends ICaseRawData {
//   fieldValues: Record<string, IProcessedField>;
// }

export const enum FIELD_TYPE {
  TEXT = 'text',
  SELECT = 'select',
  CHIPS = 'chips'
}

export const enum FIELD_VARIANT {
  PLAIN = 'plain',
  STANDARD = 'standard'
}

export interface IOptionMetaData {
  value: string;
  isSelected: boolean;
  isOther: boolean;
}

export interface IFieldMetaData {
  fieldId: string;
  options: IOptionMetaData[];
}

export interface IDynamicField {
  hasOther: boolean;
  otherFieldProps: {
    value: string[];
    options: string[];
  };
  formattedTextValue: string;
  name: string;
  label: string;
  id: string;
  isPmm: boolean;
  multiline: boolean;
  options: ISelectOption[];
  value: IDynamicFieldValue;
  type: FIELD_TYPE;
  multiple: boolean;
  placeholder: string;
  withHint: boolean;
  variant: FIELD_VARIANT;
  disabled: boolean;
  editMode: boolean;
  createdAt: string | undefined;
  updatedAt: string | undefined;
  updatedBy: IUserDataWithRoles | undefined;
  update: (values: IDynamicFieldValues) => Promise<IServerProcessedField>;
  idx: number;
}

export type IDynamicFieldValues = Record<string, IDynamicFieldValue>;
export type IDynamicFieldValue = string[];

export interface IAttendingDefault {
  values: Record<string, IFieldValues>;
  never_save_defaults: boolean;
}

export interface IFieldValues {
  values: string[];
  otherValues: string[];
}

export interface ICaseFields {
  description?: string;
}

export interface IMetaFields {
  caseDate?: string;
  procedureTitle?: string;
}

export interface IPMMFields {
  id: string;
  values: string[];
  otherValues: string[];
}

export interface IPMMFieldValue {
  value: string;
  op: string;
}

export interface ICaseFieldValueData {
  fieldId: string;
  values: string[];
  otherValues: string[];
  createdAt: string;
  updatedAt: string;
  planFeedbacks: IPlanFeedbackData[];
  updatedByUser: IUserDataWithRoles;
  formattedTextValue: string;
}

export interface IPlanFeedbackData {
  caseId: string;
  rating: number;
  text: string;
  user: IUserDataWithRoles;
}

export interface IMeta {
  key: string;
  value: string;
}

export interface IHasuraTokenData {
  roles: ROLES[];
  userId: string;
  sitesIds: string;
}

export interface ICasesByProcedureData {
  procedureId: string;
  count: number;
  siteId: string;
  userId: string;
}

export interface IProcedureData {
  id: string;
  isUserTemplate?: boolean;
  name: string;
  specialtyId: string;
  specialtyTitle: string;
}

export interface IProcedureSelectOption extends ISelectOption {
  countPerSiteAndAtt?: Map<string, Map<string, number>>;
  weight?: number;
  pastCasesMatch?: boolean;
  isUserTemplate: boolean;
}

export interface IProcCountPerSurgeon extends Map<string, number> {}

export interface IProcCountSiteToSurgeon extends Map<string, IProcCountPerSurgeon> {}

export interface IProcedureDataWithCount extends IProcedureData {
  countPerSiteAndAtt?: IProcCountSiteToSurgeon;
  weight?: number;
  pastCasesMatch?: boolean;
  template?: any; // Add the 'template' field
  userId?: string;
}

export interface ISelectOption {
  label: string;
  value: string;
}

export interface IUserSelectOption extends ISelectOption {
  avatar: string;
  firstName: string;
  lastName: string;
  userId: string;
  roles: IRoleData[];
}

export interface ICaseFollowersInput {
  caseId: string;
  userId: string;
}

export interface IProcedure {
  cptCode: string;
  id: string;
  literatures: ILiterature[];
  name: string;
  otherCptCode: string;
  otherCptCodeComment: string;
  procedureFieldDefaults: IProcedureFieldDefault[];
  specialty: ISpecialty;
  specialtyId: string;
  steps: IStep[];
  subType: ISubType;
}

export interface ILiterature {
  contributorName: string;
  title: string;
  url: string;
}

export interface IProcedureFieldDefault {
  fieldId: string;
  values: string[];
}

export interface ISpecialty {
  id: string;
  name: string;
}

export interface ISubType {
  form: IForm;
  id: string;
  procedureSubTypeFieldOptions: IProcedureSubTypeFieldOption[];
  procedureType: IProcedureType;
}

export interface IForm {
  formFields: IFormField[];
  id: string;
}

export interface IFormField {
  field: IField;
}

export interface IField {
  idx: number;
  hasOther: boolean;
  id: string;
  name: string;
  type: FIELD_TYPE;
  createdAt?: string;
  updatedAt?: string;
  updatedBy?: IUserDataWithRoles;
}

export interface IProcessedField extends IField {
  parsedOptions: string[];
  multiple: boolean;
  value: IDynamicFieldValue;
  label: string;
  otherValue: string[];
  formattedTextValue: string;
  userFieldOptions: string[];
  fieldValuesEdits: IFieldValuesEdits[];
}

export interface IProcedureSubTypeFieldOption {
  clientId: string;
  fieldId: string;
  options: string[];
}

export interface IProcedureType {
  id: string;
  name: string;
}

export interface IStep {
  enabled: boolean;
  id: string;
  metaData: IStepMetaData;
  name: string;
  order: number;
  risks: any[];
  stepMedia: IStepMedia[];
  text: string;
  title: string;
}

export interface IStepMetaData {
  stepFontMultiplier?: string;
}

export interface IStepMedia {
  enabled: boolean;
  id: string;
  mediaType: string;
  mediaUrl: string;
  metaData: IStepMediaMetaData;
  name: string;
  order: number;
  title: string;
}

export interface IStepMediaMetaData {
  alignment: string;
  height: string;
}

export interface IServerProcessedField {
  caseId: string;
  values: string;
  otherValues: string;
  fieldId: string;
  formattedTextValue: string;
  otherValuesOptions: string[];
}

export interface IServerProcessedUserFieldOptions {
  fieldId: string;
  options: string[];
  templateId: string;
}

export interface ICaseAsset {
  createdAt: string;
  description: string;
  externalId: string;
  id: string;
  token: string;
  meta: IAssetMeta;
}

export interface IAssetMeta {
  cdnUrlModifiers?: string;
  name: string;
  mimeType: string;
  stage: string;
}

export interface IOnt {
  signedUrl: string;
  version: string;
}

export interface IProcOptions {
  id: string;
  values: string;
  fieldId: string;
  updatedAt: Date;
  createdAt: Date;
  otherValues: string;
  case: {
    procedureId: string;
    procedureTitle: string;
    procedureData: string;
  };
}

export interface ICaseByDescriptionData {
  procedureId: string;
  procedureTitle: string;
}

export interface ICreateCaseInput {
  caseDate: Date;
  displayId: string;
  attendingId: string | undefined;
  siteId: string | undefined;
  state: CASE_STATE;
  description: string | undefined;
  ontologyVersion: string;
  indexInDay: number;
}

export interface IUpdateCaseInputWithoutProc {
  caseDate?: Date;
  displayId?: string;
  attendingId?: string;
  siteId?: string;
  description?: string;
  indexInDay?: number;
}

export interface IUpdateCaseInputWithProc extends IUpdateCaseInputWithoutProc {
  ontologyVersion: string;
  procedureId: string;
  procedureData: IProcedure;
  procedureTitle: string;
  procedureTypeId: string;
  procedureTypeTitle: string;
  specialtyId: string;
  specialtyTitle: string;
}

export interface ICareTeamRawDefaults {
  values: Record<string, IPMMFields>;
  procedure_id: string;
  form: string;
  user_id: string;
  stop_insights: Record<string, string[]>;
}

export interface ICareTeamDefaults {
  values: Record<string, IPMMFields>;
  stopInsights: Record<string, string[]>;
  procedureId: string;
  form: string;
  userId: string;
  fieldsOrder?: string[];
}

export interface IEnums {
  users: IUserDataWithRoles[];
  sites: ISiteData[];
  procedures: IProcedureData[];
}

export interface IAuthHeader {
  'x-hasura-role': string;
  authorization: string;
}

export enum TabsEnum {
  CASE = 'case',
  CHAT = 'chat'
}

export enum SavingStatus {
  NONE = 'none',
  SAVING = 'saving',
  SAVED = 'saved'
}

export enum CaseStatusEnum {
  UPCOMING = 'upcoming',
  CURRENT = 'current'
}

export enum IFieldRecommendationType {
  ADD = 'add',
  REMOVE = 'remove',
  NONE = 'none'
}

export enum ICasesFilter {
  MY_CASES = 'myCases',
  ALL_CASES = 'allCases',
  CATCH_UP = 'catchUp'
}

export interface IFieldRecommendation {
  fieldId: string;
  value: string;
  multi: boolean;
  recommendation: IFieldRecommendationType;
}

export interface ISearchParams {
  date?: Date;
  caseId?: string;
  tab?: TabsEnum;
}

export interface IWillNotifyInfo {
  notify: boolean;
  isNow: boolean;
  msgKey: string;
  msgOptions: Record<string, string>;
}

export interface IWillNotifyMentionedInfo {
  notify: boolean;
  msgKey: string;
  msgOptions: Record<string, string>;
}

export interface IRawFieldValuesCount {
  siteId: string;
  procedureId: string;
  fieldId: string;
  value: string;
  count: number;
  edits?: IFieldValuesEdits;
}

export interface IFieldValuesEdits {
  siteId: string;
  procedureId: string;
  fieldId: string;
  value: string;
  newValue?: string;
  deleted: boolean;
}

export interface IFieldValuesCount {
  siteId: string;
  procedureId: string;
  fieldId: string;
  value: string;
  count: number;
}

export interface IFieldValuesCountSum {
  all: IFieldValuesCount[];
  attending: IFieldValuesCount[];
}

export interface IGetFieldValuesCount {
  all: Map<string, number>;
  attendingAllProcs: Map<string, number>;
  attendingCurProc: Map<string, number>;
}

export interface ICaseReferencedData {
  caseStateLogs: ICaseStateLogData[];
  procedureData: IProcedure | null;
  caseFieldValues: ICaseFieldValueData[];
  fieldValues: Map<string, IProcessedField>;
  planFeedbacks: IPlanFeedbackData[];
  addedFields: IUserFormField[];
  removedFields: string[];
}

export interface ICaseBasicData {
  id: string;
  startTime: string;
  siteId: string;
  site: ISiteData;
  displayId: string;
  description: string;
  specialtyId: string;
  specialtyTitle: string;
  procedureId: string | null;
  procedureTitle: string;
  createdAt: string;
  updatedAt: string;
  isAddOn: boolean;
  state: CASE_STATE;
  caseDateStr: string;
  attendingId: string;
  attending: IUserDataWithRoles | null;
  editedByUser: IUserDataWithRoles | null;
  indexInDay: number;
  standardProcedure: boolean;
  status: string;
  caseDate: Date;
  isReady: boolean;
  intraopText: string | null;
  caseFollowers: ICaseFollowerData[];
  lastValueUpdate: Date | null;
  lastValueUpdatedById: string | null;
  room: string | null;
  patientInitials: string | null;
  mrn: string | null;
  editedAt: string | null;
  editedBy: string | null;
  comments: ICommentData[];
}

export interface IAmplUserData {
  roles: ROLES[];
  services: string[];
  serviceIds: string[];
  isContributor: boolean;
  id: string;
  email: string;
}

export interface IAmplSessionData {
  isPwa: boolean;
  isOneSignalEnabled: boolean;
  isPushOptIn: boolean;
}

export interface IUserTemplate {
  id: string;
  userId: string;
  procedureId: string;
  procedureName: string;
  specialtyId: string;
  specialtyTitle: string;
}

export interface IUserFieldOption {
  userId: string;
  templateId: string;
  fieldId: string;
  options: string[];
  createdAt: string;
  updatedAt: string;
}

export interface IUserFormField {
  idx: number;
  userId: string;
  templateId: string;
  fieldId: string;
  fieldType: string;
  fieldName: string;
  createdAt: string;
  updatedAt: string;
}

export interface IServerProcessedUserFormField {
  userId: string;
  templateId: string;
  fieldId: string;
  fieldType: string;
  fieldName: string;
}

export interface IUserRemovedFormField {
  userId: string;
  templateId: string;
  fieldId: string;
  createdAt: string;
  updatedAt: string;
}

export interface IServerProcessedUserRemovedFormField {
  userId: string;
  templateId: string;
  fieldId: string;
}

export interface IChatSuggestionAction {
  text: string;
  onClick: () => void;
}

export interface IChatSuggestion {
  commentId: string;
  text: string;
  actions: IChatSuggestionAction[];
}
