import React, { type MouseEvent } from 'react';
import type { IChatSuggestion, IChatSuggestionAction } from '../../mobxStore/types';
import { useTranslation } from 'react-i18next';
import Button from 'app/components/buttons/Button';
import CheifyLogo from 'app/assets/icons/ChiefyLogo_icon';

interface IProps {
  commentSuggestion: IChatSuggestion;
  commentTime: string;
  isProcessingSuggestions?: boolean;
}

const CommentSuggestion = (props: IProps): React.JSX.Element => {
  const { commentSuggestion, commentTime, isProcessingSuggestions } = props;
  const { t } = useTranslation();

  const handleClickAction = async (action: IChatSuggestionAction): Promise<void> => {
    console.log('Action clicked:', action);
    action.onClick();
  };

  return (
    <div className="comment-suggestion">
      <div className="visibility-label">{t('visibleOnlyToYou')}</div>
      <div className="suggestion-logo">
        <CheifyLogo className="logo" />
        {t('chiefyAi')}
      </div>
      <div className="comment-bubble">
        <div className="comment-text">{commentSuggestion.text}</div>
        <div className="actions">
          {commentSuggestion.actions.map(action => (
            <Button
              key={action.text}
              classNames="primary action"
              onClick={async (e: MouseEvent) => {
                void handleClickAction(action);
              }}
              buttonText={action.text}
              disabled={isProcessingSuggestions}
            />
          ))}
        </div>
        <div className="comment-time">{commentTime}</div>
      </div>
    </div>
  );
};

export default CommentSuggestion;
