import React from 'react';
import { useRootStore } from 'app/mobxStore';
import { observer } from 'mobx-react-lite';
import type { IDynamicFieldValues, IProcedureSelectOption } from 'app/mobxStore/types';
import { FORM_FIELDS_ENUM, type IFormData } from 'app/components/fields/types';
import type { Control } from 'react-hook-form/dist/types/form';
import { USER_CASE_ROLES } from '../../consts';
import CaseDescription from '../fields/CaseDescription';
import './FillCaseMetaForm.scss';
import ProcedureSelectWithConfirm from 'app/components/fields/procedureSelect/ProcedureSelectWithConfirm';
import HuddleTemplate from '../huddleTemplate/HuddleTemplate';

interface IProps {
  showSubmit: boolean;
  control: Control<IFormData>;
  allValues: IFormData;
  handleProcedureChange: (procedure: IProcedureSelectOption, approved: boolean) => Promise<boolean>;
  loadTemplate?: () => Promise<void>;
  briefFormAllValues?: IDynamicFieldValues;
  briefFormReset?: (values: IDynamicFieldValues) => void;
}

const FillCaseMetaForm = (props: IProps): React.JSX.Element => {
  const {
    handleProcedureChange,
    allValues,
    control,
    loadTemplate,
    briefFormAllValues,
    briefFormReset
  } = props;
  const { hasLock, caseStore, userRoleInCase, curEditMode, canUpdate, careTeamDefaultsStore } =
    useRootStore();
  const editable = canUpdate && curEditMode;

  const openedCase = caseStore.openedCase;
  if (openedCase === null) {
    return <></>;
  }

  const hasSavedPreference =
    openedCase.basicCase.data.procedureId !== null &&
    careTeamDefaultsStore.hasDefault(
      openedCase.basicCase.data.attendingId,
      openedCase.basicCase.data.procedureId
    );

  const showProcedureSelect = editable;
  const showDescription =
    allValues[FORM_FIELDS_ENUM.PROCEDURE] !== undefined &&
    !openedCase.basicCase.data.standardProcedure &&
    (editable || allValues[FORM_FIELDS_ENUM.DESCRIPTION]) &&
    !hasLock;
  const showHuddleTemplate =
    editable && showDescription && userRoleInCase !== USER_CASE_ROLES.NONE && loadTemplate;

  return (
    <form id="fill-case-form" className="fill-case-container">
      <>
        {showProcedureSelect && (
          <ProcedureSelectWithConfirm
            control={control}
            allValues={allValues}
            handleProcedureChange={handleProcedureChange}
          />
        )}
        {showHuddleTemplate && (
          <HuddleTemplate
            hasSavedPreference={hasSavedPreference}
            loadTemplate={loadTemplate}
            briefFormAllValues={briefFormAllValues}
            briefFormReset={briefFormReset}
          />
        )}
        {showDescription && <CaseDescription control={control} isReadOnly={!editable} />}
      </>
    </form>
  );
};

export default observer(FillCaseMetaForm);
