import React, { type MouseEvent, useState } from 'react';
import {
  FIELD_TYPE,
  FIELD_VARIANT,
  type IDynamicField,
  type IDynamicFieldValues,
  type IFullCase,
  type IServerProcessedField,
  type IUserDataWithRoles
} from 'app/mobxStore/types';
import Button from 'app/components/buttons/Button';
import PlusCircleIcon from 'app/assets/icons/PlusCircle_icon';
import { useRootStore } from '../../mobxStore';
import ErrorMonitor from '../../services/errorMonitor/errorMonitor';
import AddFieldPanel from './AddFieldPanel';
import AmplService from '../../services/amplService/amplService';
import { useTranslation } from 'react-i18next';
import type { TFunction } from 'i18next';
import { submitPmmField } from '../../../utils/form/submit';

interface IProps {
  field: IDynamicField;
  idx: number;
}

const createMN = (name: string): string => {
  return name.trim().replace(/\s+/g, '_');
};

const createField = (
  t: TFunction<'translation', undefined>,
  user: IUserDataWithRoles,
  caseId: string,
  fieldName: string,
  fieldType: FIELD_TYPE,
  idx: number
): IDynamicField => {
  const id = createMN(fieldName);
  const hasOther = fieldType === FIELD_TYPE.SELECT;
  const multiple = fieldType === FIELD_TYPE.SELECT;
  const multiline = fieldType === FIELD_TYPE.TEXT;

  const field = {
    hasOther,
    otherFieldProps: {
      value: [],
      options: []
    },
    name: fieldName,
    label: fieldName,
    id,
    isPmm: true,
    multiline,
    options: [
      {
        label: t('addYourOwn'),
        value: 'Other'
      }
    ],
    value: [],
    type: fieldType,
    multiple,
    placeholder: '',
    withHint: false,
    variant: FIELD_VARIANT.STANDARD,
    disabled: false,
    editMode: true,
    createdAt: new Date().toString(),
    updatedAt: new Date().toString(),
    updatedBy: user,
    update: async (values: IDynamicFieldValues): Promise<IServerProcessedField> => {
      ErrorMonitor.captureException(new Error('edit mode should not call update'));
      return {
        caseId,
        fieldId: id,
        values: '',
        otherValues: '',
        otherValuesOptions: [],
        formattedTextValue: ''
      };
    },
    formattedTextValue: '',
    idx
  };

  return field;
};

const AddFieldButton = (props: IProps): React.JSX.Element => {
  const { caseStore, userStore } = useRootStore();
  const { idx } = props;
  const user = userStore.loggedInUser;
  const openedCase = caseStore.openedCase as IFullCase;
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const { t } = useTranslation();
  const caseData = openedCase.basicCase.data;
  const handleAddField = async (fieldName: string, fieldType: FIELD_TYPE): Promise<void> => {
    const field = createField(t, user.data, openedCase.basicCase.id, fieldName, fieldType, idx);
    const update = async (values: IDynamicFieldValues): Promise<IServerProcessedField> => {
      return await submitPmmField(caseData.id, field, values, caseData.procedureId ?? '');
    };
    field.update = update;
    openedCase.addField(field);
  };

  return (
    <div className="add-field-wrapper">
      <Button
        classNames="btn circle-button add-field-button"
        startIcon={<PlusCircleIcon strokeWidth={1.5} />}
        onClick={async (e: MouseEvent) => {
          setIsPanelOpen(true);
          AmplService.sendCaseEvent(
            AmplService.EVENTS.EDIT_PREFERENCES_ADD_FIELD,
            openedCase?.basicCase
          );
        }}
      />
      <AddFieldPanel
        isPanelOpen={isPanelOpen}
        setIsPanelOpen={setIsPanelOpen}
        handleAddField={handleAddField}
      />
    </div>
  );
};

export default AddFieldButton;
