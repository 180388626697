import { observer } from 'mobx-react-lite';
import React from 'react';
import type { Control } from 'react-hook-form/dist/types/form';
import type { IDynamicField, IFullCase } from 'app/mobxStore/types';
import { useController } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import TextInput from '@mui/material/TextField';
import Button from 'app/components/buttons/Button';
import CheckIcon from 'app/assets/icons/Check_icon';
import TrashIcon from 'app/assets/icons/Trash_icon';
import variables from 'app/utils/variables.module.scss';
import './OtherTextField.scss';
import { useRootStore } from '../../mobxStore';

interface IProps {
  control: Control<any>;
  field: IDynamicField;
  doneEditing: (count: number) => void;
  index: number;
  value: string;
  isEditTemplate: boolean;
}

const splitOtherValue = (value: string): string[] => {
  return value
    .split(',')
    .map(v => v.trim())
    .filter(v => v !== '');
};

const OtherMultiTextField = (props: IProps): React.JSX.Element => {
  const [textValue, setTextValue] = React.useState<string>('');
  const { isEditTemplate, doneEditing, value, control, field: dField } = props;
  const { caseStore } = useRootStore();
  const openedCase = caseStore.openedCase as IFullCase;
  const { field } = useController({
    name: `${dField.id}-other`,
    control
  });
  const { onChange } = field;
  const onDoneEditing = (): void => {
    const curValue = textValue;
    setTextValue('');
    // if user creating new chip and not typing anything, simply stop editing

    if (curValue === undefined) {
      doneEditing(0);
      return;
    }
    const fieldValue = field.value ?? [];
    // if user editing existing chip and erasing string or creating new, typing and then erasing, then remove from array and stop editing
    if (curValue.trim() === '' && props.index === fieldValue.length - 1) {
      onChange([...fieldValue.slice(0, props.index), ...fieldValue.slice(props.index + 1)]);
      const newValues = [
        ...dField.otherFieldProps.options.slice(0, props.index),
        ...dField.otherFieldProps.options.slice(props.index + 1)
      ];
      openedCase.updateUserFieldOptionsToStore(dField.id, newValues);
      if (!isEditTemplate) {
        void openedCase.upsertUserFieldOptionsToServer(dField.id, newValues);
      }
      doneEditing(0);
      return;
    }
    if (curValue.trim() === '') {
      onChange([...fieldValue.slice(0, props.index), ...fieldValue.slice(props.index + 1)]);
      const newValues = [
        ...dField.otherFieldProps.options.slice(0, props.index),
        ...dField.otherFieldProps.options.slice(props.index + 1)
      ];
      if (!isEditTemplate) {
        void openedCase.upsertUserFieldOptionsToServer(dField.id, newValues);
      }
      openedCase.updateUserFieldOptionsToStore(dField.id, newValues);

      doneEditing(-1);
      return;
    }
    const splitValues = splitOtherValue(curValue);
    onChange([
      ...fieldValue.slice(0, props.index),
      ...splitValues,
      ...fieldValue.slice(props.index + 1)
    ]);
    const newValues = [
      ...dField.otherFieldProps.options.slice(0, props.index),
      ...splitValues,
      ...dField.otherFieldProps.options.slice(props.index + 1)
    ];
    if (!isEditTemplate) {
      void openedCase.upsertUserFieldOptionsToServer(dField.id, newValues);
    }
    openedCase.updateUserFieldOptionsToStore(dField.id, newValues);
    doneEditing(splitValues.length);
  };
  return (
    <FormControl fullWidth>
      <div className="other-text-field-container multi">
        <TextInput
          className="other-text-field"
          multiline
          fullWidth
          {...field}
          value={textValue}
          onChange={e => {
            setTextValue(e.target.value);
          }}
          autoFocus
          onFocus={e => {
            setTimeout(() => {
              e.target.select();
            });
          }}
          onBlur={() => {
            onDoneEditing();
          }}
        />
        <Button
          classNames="btn circle-button save-button"
          startIcon={
            value === '' ? (
              <TrashIcon height={10} fill={variables.primaryPurple} />
            ) : (
              <CheckIcon height={10} stroke={variables.primaryPurple} />
            )
          }
        />
      </div>
    </FormControl>
  );
};

export default observer(OtherMultiTextField);
