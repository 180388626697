import * as React from 'react';
const SvgComponent = props => (
  <svg
    className="svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill={props.fill || 'none'}
    {...props}
  >
    <path
      d="M9.99992 8.33333C11.6108 8.33333 12.9166 7.0275 12.9166 5.41667C12.9166 3.80584 11.6108 2.5 9.99992 2.5C8.38909 2.5 7.08325 3.80584 7.08325 5.41667C7.08325 7.0275 8.38909 8.33333 9.99992 8.33333Z"
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 3.02283C4.24438 3.54989 3.75 4.42554 3.75 5.41671C3.75 6.47767 4.3165 7.40634 5.1635 7.91671"
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 3.02283C15.7556 3.54989 16.25 4.42554 16.25 5.41671C16.25 6.40784 15.7556 7.28354 15 7.81059"
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 16.6666V17.5H15V16.6666C15 15.1135 15 14.3369 14.7462 13.7243C14.408 12.9076 13.759 12.2587 12.9423 11.9204C12.3297 11.6666 11.5531 11.6666 10 11.6666C8.44687 11.6666 7.67029 11.6666 7.05771 11.9204C6.24096 12.2587 5.59204 12.9076 5.25375 13.7243C5 14.3369 5 15.1135 5 16.6666Z"
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.3332 17.5V17C18.3332 15.1332 18.3332 14.1998 17.9699 13.4867C17.6503 12.8595 17.1404 12.3496 16.5132 12.03"
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.66675 17.5V17C1.66675 15.1332 1.66675 14.1998 2.03006 13.4867C2.34964 12.8595 2.85957 12.3496 3.48678 12.03"
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgComponent;
