import variables from 'app/utils/variables.module.scss';

const sx = {
  '& input': {
    width: '100%',
    height: 44,
    backgroundColor: variables.grey2,
    borderRadius: 40,
    border: 'none',
    padding: '0 16px',
    boxSizing: 'border-box',
    fontSize: 14,
    color: variables.black,
    '&::placeholder': {
      color: variables.grey5
    },
    '&.focused': {
      outline: `1px solid ${variables.primaryPurple}`
    },
    '&:focus-visible': {
      outline: `1px solid ${variables.primaryPurple}`
    }
  },
  '& .panel-header': {
    borderBottom: '1px solid',
    borderColor: variables.grey2
  },
  '& .followers': {
    borderTop: '1px solid',
    borderColor: variables.grey2,
    marginTop: '-20px',
    position: 'relative',
    '&::after, &::before': {
      content: "''",
      height: 10,
      width: '100%',
      position: 'absolute',
      left: 0,
      zIndex: 1
    },
    '&::after': {
      background: 'linear-gradient(to top, #fff, transparent)',
      bottom: 0
    },
    '&::before': {
      background: 'linear-gradient(to bottom, #fff, transparent)',
      top: 0
    }
  },
  '& .follower-chips-container': {
    padding: '14px 24px 0',
    display: 'flex',
    flexWrap: 'wrap',
    maxHeight: 108, // 3 rows of chips
    overflow: 'auto'
  },
  '& .input-container': {
    position: 'relative',
    padding: '32px 24px',
    display: 'flex',
    boxSizing: 'border-box',
    '& .done-button': {
      flexShrink: 0,
      marginLeft: '8px',
      fontWeight: 600,
      height: '44px'
    },
    '& .back-button': {
      flexShrink: 0,
      marginRight: '8px',
      height: '44px',
      '.svg-icon': {
        height: 14
      }
    },
    '& .input-wrapper': {
      width: '100%',
      position: 'relative'
    },
    '& .clear-button': {
      height: '40px',
      width: '40px',
      backgroundColor: variables.white,
      position: 'absolute',
      right: 2,
      top: 2
    }
  }
};

export default sx;
