import React, { type MouseEvent, useState } from 'react';
import type { ICase, ICommentData } from 'app/mobxStore/types';
import { useTranslation } from 'react-i18next';
import Button from 'app/components/buttons/Button';
import Comment from 'app/components/commments/Comment';

interface IProps {
  kase: ICase;
}

const CaseListItemComments = (props: IProps): React.JSX.Element => {
  const {
    kase: { data: caseData }
  } = props;
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const lastRead = props.kase.commentsStore.lastReadCommentId;

  const getUnreadComments = (): ICommentData[] => {
    const commentsArray: ICommentData[] = [];
    caseData.comments.forEach((comment, index) => {
      const lastReadIndex = caseData.comments.findIndex(c => c.id === lastRead);
      if (index > lastReadIndex) {
        commentsArray.push(comment);
      }
    });
    return commentsArray;
  };
  const unreadComments = getUnreadComments();
  const isLessThanThreeUnreads = unreadComments.length < 3;

  const getVisibleComments = (): ICommentData[] => {
    if (isExpanded || isLessThanThreeUnreads) {
      return unreadComments;
    }
    return [unreadComments[0], unreadComments[unreadComments.length - 1]];
  };
  const visibleComments = getVisibleComments();

  const getMoreButtonText = (): string => {
    if (isExpanded) {
      return 'showLess';
    }
    return t('moreMessages', { count: unreadComments.length - 2 }).toString();
  };

  const moreBtnText = getMoreButtonText();

  return (
    <div className="case-list-item-comments">
      {visibleComments.map((comment, index) => (
        <div key={comment.id}>
          <Comment
            key={comment.id}
            comment={comment}
            isPreviousCommentBySameUser={
              visibleComments[visibleComments.indexOf(comment) - 1]?.user.id === comment.user.id
            }
          />
          {!isLessThanThreeUnreads && !isExpanded && index !== visibleComments.length - 1 && (
            <div className="more-separator">
              <Button
                classNames="action-button"
                buttonText={moreBtnText}
                onClick={async (e: MouseEvent) => {
                  setIsExpanded(!isExpanded);
                  e.stopPropagation();
                }}
              />
            </div>
          )}
        </div>
      ))}
      <div className="actions">
        {isExpanded && (
          <Button
            classNames="action-button more-button"
            buttonText={moreBtnText}
            onClick={async (e: MouseEvent) => {
              setIsExpanded(!isExpanded);
              e.stopPropagation();
            }}
          />
        )}
        <Button
          classNames="action-button mark-as-read-button"
          buttonText="markAsRead"
          onClick={async (e: MouseEvent) => {
            void props.kase.commentsStore.commentsMarkAllAsRead();
            e.stopPropagation();
          }}
        />
      </div>
    </div>
  );
};

export default CaseListItemComments;
