import type {
  ICase,
  ICaseReferencedData,
  IDynamicField,
  IFieldValuesEdits,
  IFullCase,
  IPlanFeedbackData,
  IProcedure,
  IServerProcessedUserFormField,
  IServerProcessedUserRemovedFormField
} from './types';
import { flow, makeAutoObservable } from 'mobx';
import apiService from '../services/apiService';

class FullCase implements IFullCase {
  addedFields: IDynamicField[] = [];
  removedFields: string[] = [];
  basicCase: ICase;
  fieldsOrder: string[] = [];
  referencedData: ICaseReferencedData;

  setFieldsOrder: (order: string[]) => void = (order: string[]) => {
    this.fieldsOrder = order;
  };

  constructor(referencedData: ICaseReferencedData, basicCase: ICase) {
    makeAutoObservable(this, {});
    this.referencedData = referencedData;
    this.basicCase = basicCase;
  }

  removeField(field: IDynamicField): void {
    !this.addedFields.some(f => f.id === field.id)
      ? this.removedFields.push(field.id)
      : (this.addedFields = this.addedFields.filter(f => f.id !== field.id));
    this.fieldsOrder = this.fieldsOrder.filter(f => f !== field.id);
  }

  addField(field: IDynamicField): void {
    this.addedFields.push(field);
    this.fieldsOrder.splice(field.idx, 0, field.id);
  }

  clearFields(): void {
    this.addedFields = [];
    this.removedFields = [];
    this.fieldsOrder = [];
  }

  setReferencedData = (referencedData: ICaseReferencedData): void => {
    this.referencedData = referencedData;
  };

  setProcedure(procedure: IProcedure): void {
    this.referencedData.procedureData = procedure;
    this.basicCase.data.procedureId = procedure.id;
    this.basicCase.data.procedureTitle = procedure.name;
  }

  updatePlanFeedback(rating: number, text: string): void {
    void this.upsertPlanFeedbackToServer(rating, text);
    this.upsertPlanFeedbackToStore(rating, text);
  }

  deletePlanFeedbackFromStore(): void {
    this.referencedData.planFeedbacks = this.referencedData.planFeedbacks.filter(
      pf => pf.user.id !== this.basicCase.store.rootStore.userStore.loggedInUser.data.id
    );
  }

  upsertPlanFeedbackToServer = flow(function* (this: IFullCase, rating: number, text: string) {
    yield apiService.upsertPlanFeedback(this.basicCase.id, rating, text);
  });

  upsertPlanFeedbackToStore(rating: number, text: string): void {
    this.referencedData.planFeedbacks = this.referencedData.planFeedbacks.filter(
      pf => pf.user.id !== this.basicCase.store.rootStore.userStore.loggedInUser.data.id
    );

    this.referencedData.planFeedbacks.push({
      caseId: this.basicCase.id,
      rating,
      text,
      user: this.basicCase.store.rootStore.userStore.loggedInUser.data
    });
  }

  deletePlanFeedbackFromServer = flow(function* (this: IFullCase) {
    yield apiService.deletePlanFeedback(
      this.basicCase.id,
      this.basicCase.store.rootStore.userStore.loggedInUser.data.id
    );
  });

  getCurrentUserPlanFeedback(): IPlanFeedbackData {
    const feedback = this.referencedData.planFeedbacks.find((fb: IPlanFeedbackData) =>
      this.basicCase.store.rootStore.isLoggedInUserId(fb.user.id)
    );
    if (feedback) {
      return feedback;
    }

    return {
      caseId: this.basicCase.id,
      rating: 0,
      text: '',
      user: this.basicCase.store.rootStore.userStore.loggedInUser.data
    };
  }

  updateUserAddedFieldOptionsToStore = (fieldId: string, values: string[]): void => {
    const field = this.addedFields.find(f => f.id === fieldId);
    if (!field) {
      return;
    }
    field.otherFieldProps.options = values;
  };

  updateUserFieldOptionsToStore = (fieldId: string, values: string[]): void => {
    const field = this.referencedData.fieldValues.get(fieldId);
    if (!field) {
      this.updateUserAddedFieldOptionsToStore(fieldId, values);
      return;
    }
    field.userFieldOptions = values;
  };

  updateFieldValuesEditsToStore = (fieldId: string, value: IFieldValuesEdits): void => {
    const field = this.referencedData.fieldValues.get(fieldId);
    if (!field) {
      return;
    }
    field.fieldValuesEdits.push(value);
  };

  upsertUserFieldOptionsToServer = flow(function* (
    this: IFullCase,
    fieldId: string,
    options: string[]
  ) {
    const userId = this.basicCase.data.attendingId;
    const objects = [
      {
        userId,
        fieldId,
        options,
        templateId: this.basicCase.data.procedureId as string
      }
    ];
    yield apiService.upsertUserFieldOptions(objects);
  });

  upsertUserFormFieldsToServer = flow(function* (
    this: IFullCase,
    objects: IServerProcessedUserFormField[]
  ) {
    yield apiService.upsertUserFormFields(objects);
  });

  upsertUserRemovedFormFieldsToServer = flow(function* (
    this: IFullCase,
    objects: IServerProcessedUserRemovedFormField[]
  ) {
    yield apiService.upsertUserRemovedFormFields(objects);
  });
}

export default FullCase;
