import apiService from 'app/services/apiService';
import {
  FIELD_TYPE,
  type IDynamicField,
  type IDynamicFieldValues,
  type IPMMFields,
  type IServerProcessedField
} from 'app/mobxStore/types';

export const submitPmmField = async (
  caseId: string,
  field: IDynamicField,
  values: IDynamicFieldValues,
  templateId: string
): Promise<IServerProcessedField> => {
  const parseFunction = processField(caseId, values);
  const pmmValue = parseFunction(field);

  await apiService.updatePmmValue(caseId, field.id, pmmValue);
  // await apiService.upsertUserFieldOptions(processedUserFieldOptions);
  return pmmValue;
};

const processDataForDefault = (
  fields: IDynamicField[],
  values: IDynamicFieldValues
): Record<string, IPMMFields> => {
  const acc: Record<string, IPMMFields> = {};
  filterPmmFields(fields).reduce(
    (previousValue: Record<string, IPMMFields>, field: IDynamicField, currentIndex: number) => {
      previousValue[field.id] = processFieldForDefault(field, values);
      return previousValue;
    },
    acc
  );
  return acc;
};

const filterPmmFields = (fields: IDynamicField[]): IDynamicField[] => {
  return fields.filter(field => field.isPmm);
};

const assertArray = (value: any): any[] => {
  if (value === null) {
    return [];
  }
  if (Array.isArray(value)) {
    return value;
  }
  return [value];
};

const getValuesFromField = (
  field: IDynamicField,
  values: IDynamicFieldValues
): { values: string[]; otherValues: string[] } => {
  const fVals = values[field.id];
  const arrayValue = assertArray(fVals);

  const parsedValues = getValueFromModel(arrayValue, field.type);
  // remove this brake things, need to check why and remove.
  const otherValue = assertArray(values[`${field.id}-other`]);

  const hasOtherValue = otherValue !== undefined && otherValue.length !== 0 && otherValue[0] !== '';
  const otherValues = hasOtherValue ? otherValue : [];
  const cleanValues = parsedValues.filter(value => !!value);
  const cleanOtherValues = otherValues.filter(value => !!value);
  return {
    values: cleanValues,
    otherValues: cleanOtherValues
  };
};

const processField = (
  caseId: string,
  origValues: IDynamicFieldValues
): ((field: IDynamicField) => IServerProcessedField) => {
  return (field: IDynamicField) => {
    const { values, otherValues } = getValuesFromField(field, origValues);
    let formattedTextValue = '';
    if (field.type === FIELD_TYPE.TEXT && values.length > 0) {
      formattedTextValue = values[0];
      values.shift();
    }
    const processedValues = parseToTextArray(values);
    const processedOtherValues = parseToTextArray(otherValues);
    const otherValuesOptions = field.otherFieldProps.options;
    return {
      caseId,
      fieldId: field.id,
      values: processedValues,
      otherValues: processedOtherValues,
      formattedTextValue,
      otherValuesOptions
    };
  };
};

const processFieldForDefault = (
  field: IDynamicField,
  origValues: IDynamicFieldValues
): IPMMFields => {
  const { values, otherValues } = getValuesFromField(field, origValues);

  return {
    id: field.id,
    values,
    otherValues
  };
};

const parseToTextArray = (array: string[]): string =>
  JSON.stringify(array).replace('[', '{').replace(']', '}').replace(/\\n/g, '\n');

export const reverseParseToTextArray = (str: string): string[] => {
  const replacedStr = str.replace('\n', '\\n').replace('{', '[').replace('}', ']');
  return JSON.parse(replacedStr);
};

const getValueFromModel = (modelValue: string[], type: FIELD_TYPE): string[] => {
  return type === FIELD_TYPE.SELECT
    ? getSelectValueFromModel(modelValue)
    : getTextValueFromModel(modelValue);
};

const getSelectValueFromModel = (modelValue: string[]): string[] => {
  return modelValue;
};

const getTextValueFromModel = (modelValue: string[]): string[] => {
  if (modelValue.length > 0) {
    return modelValue;
  }
  return [];
};

export const submitDefault = async (
  userId: string,
  procedureId: string,
  fields: IDynamicField[],
  values: IDynamicFieldValues,
  fieldsOrder: string[]
): Promise<void> => {
  const parsedValues = processDataForDefault(fields, values);

  await apiService.submitCareTeamDefaults(userId, procedureId, parsedValues, fieldsOrder);
};

export const submitRawDefault = async (
  userId: string,
  procedureId: string,
  values: Record<string, IPMMFields>,
  fieldsOrder: string[]
): Promise<void> => {
  await apiService.submitCareTeamDefaults(userId, procedureId, values, fieldsOrder);
};
