import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEditor, EditorContent } from '@tiptap/react';
import type { ICaseFollowerData } from 'app/mobxStore/types';
import StarterKit from '@tiptap/starter-kit';
import Mention from '@tiptap/extension-mention';
import ListKeymap from '@tiptap/extension-list-keymap';
import Placeholder from '@tiptap/extension-placeholder';
import { getMentionSuggestionOptions } from './MentionSuggestionOptions';
import Button from 'app/components/buttons/Button';
import './RichTextField.scss';

import bulletListIcon from '../../assets/bullets.png';
import numberedListIcon from '../../assets/list.png';
import italicIcon from '../../assets/italic.png';
import boldIcon from '../../assets/bold.png';
import strikethroughIcon from '../../assets/strikethrough.png';

interface IProps {
  onChange?: (value: string, rawValue: string) => void;
  value?: string;
  readonly?: boolean;
  placeholderText?: string;
  mentionOptions?: ICaseFollowerData[];
  focus?: boolean;
}

const RichTextField = (props: IProps): React.JSX.Element => {
  const { onChange, value, readonly, placeholderText, mentionOptions, focus } = props;
  const [initValue, setInitValue] = useState<string | undefined>(value);
  const mentionSuggestionOptions = mentionOptions
    ? getMentionSuggestionOptions(mentionOptions)
    : undefined;
  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({ placeholder: placeholderText ?? '' }),
      ListKeymap,
      ...(mentionOptions
        ? [
            Mention.configure({
              HTMLAttributes: {
                class: 'mention'
              },
              suggestion: mentionSuggestionOptions
            })
          ]
        : [])
    ],
    editorProps: { attributes: { class: 'editor' } }
  });

  useEffect(() => {
    if (editor && value !== editor.getHTML()) {
      editor.commands.setContent(value ?? '');
    }
  }, [value]);

  useEffect(() => {
    editor?.setEditable(!readonly);
  }, [editor, readonly]);

  useEffect(() => {
    if (focus) {
      editor?.commands.focus();
    }
  }, [focus]);

  if (!editor) {
    return <></>;
  }

  if (readonly) {
    return (
      <div className="rich-text-field read-only">
        <div className="editor-container">
          <EditorContent editor={editor} value={value} />
        </div>
      </div>
    );
  }

  editor.on('update', ({ editor }) => {
    if (onChange) {
      // dont call onChange if editor is empty and has not been changed
      if (editor.isEmpty || editor.getText() === initValue) {
        return;
      }
      setInitValue(editor.getText());
      onChange(editor.getHTML(), editor.getText());
    }
  });

  return (
    <div className="rich-text-field">
      <div className="control-group">
        <div className="button-group">
          <Button
            classNames={clsx('button', { 'is-active': editor.isActive('bold') })}
            buttonContent={<img className="button-icon" src={boldIcon} />}
            onClick={async e => {
              editor.chain().focus().toggleBold().run();
            }}
          />
          <Button
            classNames={clsx('button', { 'is-active': editor.isActive('italic') })}
            buttonContent={<img className="button-icon" src={italicIcon} />}
            onClick={async e => {
              editor.chain().focus().toggleItalic().run();
            }}
          />
          <Button
            classNames={clsx('button', { 'is-active': editor.isActive('strike') })}
            buttonContent={<img className="button-icon" src={strikethroughIcon} />}
            onClick={async e => {
              editor.chain().focus().toggleStrike().run();
            }}
          />
          <Button
            classNames={clsx('button', { 'is-active': editor.isActive('bulletList') })}
            buttonContent={<img className="button-icon" src={bulletListIcon} />}
            onClick={async e => {
              editor.chain().focus().toggleBulletList().run();
            }}
          />
          <Button
            classNames={clsx('button', { 'is-active': editor.isActive('orderedList') })}
            buttonContent={<img className="button-icon" src={numberedListIcon} />}
            onClick={async e => {
              editor.chain().focus().toggleOrderedList().run();
            }}
          />
        </div>
      </div>
      <div className="editor-container">
        <EditorContent editor={editor} value={value} />
      </div>
    </div>
  );
};

export default observer(RichTextField);
