import { isSilent } from './isSilent';
import { isToday, isTomorrow } from '../mobxStore/timeHelper';
import type { IWillNotifyInfo, IWillNotifyMentionedInfo } from '../mobxStore/types';

const shouldSend = (): { isNow: boolean; when: string; lessThen24h: boolean } => {
  const isSilentRes = isSilent();
  if (!isSilentRes.silent) {
    return {
      isNow: true,
      when: 'now',
      lessThen24h: true
    };
  }

  // check if isSilentRes.nextActive is less than 1 day
  if (!isSilentRes.nextActive) {
    throw new Error('nextActive is not defined');
  }
  // tz: not critical p2
  // todo: add time zone
  if (isSilentRes.nextActive.diff(new Date(), 'days') < 1) {
    // format isSilentRes.nextActive to string like '6:40 AM';
    const whenStr = isSilentRes.nextActive.format('h:mm A');
    return {
      isNow: false,
      when: whenStr,
      lessThen24h: true
    };
  }
  // tz: not critical p2
  // todo: add time zone
  const whenStr = isSilentRes.nextActive.format('dddd h:mm A');
  return {
    isNow: false,
    when: whenStr,
    lessThen24h: false
  };
};

export const getWillNotifyNewCase = (
  surgeonUsedId: string,
  surgeonName: string,
  currentUserId: string,
  caseDate: Date,
  tz: string
): IWillNotifyInfo => {
  if (surgeonUsedId === currentUserId) {
    return {
      notify: false,
      isNow: false,
      msgKey: '',
      msgOptions: {}
    };
  }

  if (!isToday(caseDate, tz) && !isTomorrow(caseDate, tz)) {
    return {
      notify: false,
      isNow: false,
      msgKey: '',
      msgOptions: {}
    };
  }

  const shouldSendRes = shouldSend();
  if (shouldSendRes.isNow) {
    return {
      notify: true,
      isNow: true,
      msgKey: 'will_notify_new_case_now',
      msgOptions: { surgeonName }
    };
  }

  if (shouldSendRes.lessThen24h) {
    return {
      notify: true,
      isNow: false,
      msgKey: 'will_notify_new_case_later_less_then_day',
      msgOptions: {
        surgeonName,
        when: shouldSendRes.when
      }
    };
  }

  return {
    notify: true,
    isNow: false,
    msgKey: 'will_notify_new_case_later_more_then_day',
    msgOptions: {
      surgeonName,
      when: shouldSendRes.when
    }
  };
};

export const getWillNotifyChatMessage = (): IWillNotifyInfo => {
  const shouldSendRes = shouldSend();
  if (shouldSendRes.isNow) {
    return {
      notify: false,
      isNow: true,
      msgKey: '',
      msgOptions: {}
    };
  }

  if (shouldSendRes.lessThen24h) {
    return {
      notify: true,
      isNow: false,
      msgKey: 'will_notify_chat_msg_later_less_then_day',
      msgOptions: { when: shouldSendRes.when }
    };
  }

  return {
    notify: true,
    isNow: false,
    msgKey: 'will_notify_chat_msg_later_more_then_day',
    msgOptions: { when: shouldSendRes.when }
  };
};

export const getWillNotifyMentioned = (comment: string): IWillNotifyMentionedInfo => {
  const mentions: string[] = [];
  const doc = new DOMParser().parseFromString(comment, 'text/html');

  const mentionTagAll = doc.querySelectorAll('.mention');
  if (mentionTagAll) {
    mentionTagAll.forEach(mentionTag => {
      const mentionedName = mentionTag.getAttribute('data-label');
      if (mentionedName && !mentions.includes(mentionedName)) {
        mentions.push(mentionedName);
      }
    });
  }

  if (mentions.length > 1) {
    return {
      notify: true,
      msgKey: 'will_notify_chat_msg_only_users',
      msgOptions: { text: 'mentioned team members' }
    };
  }
  if (mentions.length === 1) {
    return {
      notify: true,
      msgKey: 'will_notify_chat_msg_only_users',
      msgOptions: { text: mentions[0] }
    };
  }
  return {
    notify: false,
    msgKey: '',
    msgOptions: {}
  };
};
