import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRootStore } from 'app/mobxStore';
import type { ICommentData, IFullCase } from 'app/mobxStore/types';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import 'app/components/orBoard/caseListItem/CaseListItem.scss';

import PencilIcon from 'app/assets/icons/Pencil_icon';
import TrashIcon from 'app/assets/icons/Trash_icon';

const CustomMenu = styled(Menu)({
  li: {
    minHeight: 44,
    paddingRight: 50,
    fontFamily: 'Inter',
    fontWeight: 400,
    '.svg-icon': {
      height: 15,
      width: 15,
      marginRight: 12
    },
    '.item-text': {
      fontSize: 14,
      lineHeight: '14px'
    }
  }
}) as typeof Menu;

interface IProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  setAnchorEl: (el: HTMLElement | null) => void;
  comment: ICommentData;
  handleSetIsEditing: (isEditing: boolean) => void;
}

const CommentMenu = (props: IProps): React.JSX.Element => {
  const { open, anchorEl, setAnchorEl, comment, handleSetIsEditing } = props;
  const { caseStore } = useRootStore();
  const { t } = useTranslation();
  const openedCase = caseStore.openedCase as IFullCase;

  const handleEditComment = (event: React.MouseEvent<HTMLLIElement>): void => {
    handleSetIsEditing(true);
    setAnchorEl(null);
  };

  const handleDeleteComment = (event: React.MouseEvent<HTMLLIElement>): void => {
    openedCase.basicCase.commentsStore.removeComment(comment);
    setAnchorEl(null);
  };

  const handleClose = (event: React.MouseEvent<HTMLLIElement>): void => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <CustomMenu
      className="comment-menu-panel"
      anchorEl={anchorEl}
      style={{ transform: 'translate(-4px, -10px)' }}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
    >
      <MenuItem className="cancel-case-button" onClick={handleEditComment}>
        <PencilIcon />
        <div className="item-text">{t('editComment')}</div>
      </MenuItem>
      <MenuItem className="cancel-case-button" onClick={handleDeleteComment}>
        <TrashIcon />
        <div className="item-text">{t('deleteComment')}</div>
      </MenuItem>
    </CustomMenu>
  );
};

export default CommentMenu;
