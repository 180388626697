import React, { type MouseEvent, useEffect, useState } from 'react';
import type { ICase, IUserSelectOption } from 'app/mobxStore/types';
import { Trans } from 'react-i18next';
import clsx from 'clsx';
import { setClickedSeenTooltip } from '../../mobxStore/storage';
import Button from 'app/components/buttons/Button';
import UserAvatar from 'app/components/userAvatar/UserAvatar';
import TooltipWrapper from 'app/components/tooltipWrapper/TooltipWrapper';
import EyeIcon from 'app/assets/icons/Eye_icon';
import CloseIcon from 'app/assets/icons/Close_icon';
import './FollowerChips.scss';
import variables from 'app/utils/variables.module.scss';
import { getFirstCareTeamRole, ROLES, ROLES_META } from '../../consts';

interface IProps {
  options: IUserSelectOption[];
  kase: ICase;
  optionToShowTooltip?: IUserSelectOption | null;
  smallChips?: boolean;
  handleRemoveFollower?: (option: IUserSelectOption) => void;
}

const FollowerChips = (props: IProps): React.JSX.Element => {
  const { options, kase, optionToShowTooltip, smallChips, handleRemoveFollower } = props;
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowTooltip(true);
    }, 500);
  }, []);

  const handleCloseTooltip = (): void => {
    setShowTooltip(false);
  };

  const getSeenIndicator = (istrue: boolean, children: JSX.Element): JSX.Element => {
    if (istrue) {
      return (
        <TooltipWrapper
          name={'seen'}
          open={showTooltip}
          handleCloseTooltip={handleCloseTooltip}
          handleSetTooltipSeen={() => {
            setClickedSeenTooltip(true);
          }}
          comp={children}
          tooltipContent={
            <Trans
              i18nKey="seenTooltipText"
              components={{
                eyeIcon: <EyeIcon stroke={variables.grey6} />,
                bold: <span style={{ fontWeight: 600 }} />
              }}
            />
          }
        />
      );
    }
    return children;
  };

  const getLabel = (option: IUserSelectOption): string => {
    const role = getFirstCareTeamRole(option.roles);

    if (role && role !== ROLES.OTHER) {
      const roleTitle = ROLES_META[role]?.title ?? role;
      return `${option.label} (${roleTitle})`;
    }

    return option.label;
  };

  return (
    <>
      {options.map(option => {
        // do not display the attending user in the list
        const doNotDisplay = option.userId === kase.data.attendingId;
        if (doNotDisplay) {
          return null;
        }
        const seen = kase.getUserLastSeen(option.userId);

        return (
          <div key={option.value} className={clsx('follower-chip', { seen, small: smallChips })}>
            <UserAvatar user={option} size={smallChips ? 'small' : 'medium'} />
            <div>{getLabel(option)}</div>
            {seen &&
              getSeenIndicator(
                optionToShowTooltip?.userId === option.userId,
                <div className="seen-icon-container">
                  <EyeIcon className="seen-icon" />
                </div>
              )}
            {handleRemoveFollower && (
              <Button
                classNames="circle-button remove-button"
                startIcon={<CloseIcon stroke={variables.white} />}
                onClick={async (e: MouseEvent) => {
                  handleRemoveFollower(option);
                }}
              />
            )}
          </div>
        );
      })}
    </>
  );
};

export default FollowerChips;
