import * as stringSimilarity from 'string-similarity';
import Fuse from 'fuse.js';

type ClusterFunction = (strings: string[], threshold: number) => string[][];

export const clusterUsingStringSimilarity: ClusterFunction = (strings, similarityThreshold) => {
  const clusters: string[][] = [];
  const usedIndices = new Set<number>();

  strings.forEach((str, index) => {
    if (!usedIndices.has(index)) {
      const cluster: string[] = [];
      usedIndices.add(index); // Mark the original string
      cluster.push(str);

      strings.forEach((innerStr, innerIndex) => {
        if (!innerStr) {
          return;
        }
        if (index !== innerIndex && !usedIndices.has(innerIndex)) {
          const similarity = stringSimilarity.compareTwoStrings(str, innerStr);
          if (similarity >= similarityThreshold) {
            cluster.push(innerStr);
            usedIndices.add(innerIndex);
          }
        }
      });

      clusters.push(cluster);
    }
  });

  return clusters;
};

export const clusterUsingFuse: ClusterFunction = (strings, threshold) => {
  const fuseOptions = {
    includeScore: true,
    isCaseSensitive: false,
    findAllMatches: false,
    threshold
  };
  const fuse = new Fuse(strings, fuseOptions);
  const clusters: string[][] = [];
  const usedIndices = new Set<number>();

  strings.forEach((str, index) => {
    if (!usedIndices.has(index)) {
      const results = fuse.search(str);
      const cluster: string[] = [str];
      usedIndices.add(index); // Mark the original string

      results.forEach(result => {
        const itemIndex = strings.indexOf(result.item);
        if (itemIndex !== index && !usedIndices.has(itemIndex)) {
          cluster.push(result.item);
          usedIndices.add(itemIndex);
        }
      });

      clusters.push(cluster);
    }
  });

  return clusters;
};

export const compareLetterDigitsOnly = (str1: string, str2: string): boolean => {
  // compare strings, case-insensitive, ignore all chars that are not letters or digits
  if (!str1 || !str2) {
    return false;
  }
  const clean = (str: string): string => str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
  return clean(str1) === clean(str2);
};

export const clusterUsingAssi: ClusterFunction = (strings, threshold) => {
  const clusters: string[][] = [];
  const usedIndices = new Set<number>();

  strings.forEach((str, index) => {
    if (!str) {
      return;
    }
    if (usedIndices.has(index)) {
      return;
    }

    const cluster: string[] = [];
    usedIndices.add(index); // Mark the original string
    cluster.push(str);

    strings.forEach((innerStr, innerIndex) => {
      if (index === innerIndex || usedIndices.has(innerIndex)) {
        return;
      }
      if (!innerStr) {
        return;
      }
      if (compareLetterDigitsOnly(str, innerStr)) {
        cluster.push(innerStr);
        usedIndices.add(innerIndex);
      }
    });
    clusters.push(cluster);
  });

  return clusters;
};

export const testStringSimilarity = (): void => {
  // Example usage
  const strings = [
    'carm',
    'C arm',
    'C-arm',
    'c arm',
    'carm stby',
    'oarm',
    'O arm',
    'O-arm',
    'o arm',
    'oarm stby'
  ];

  for (let i = 0; i <= 1; i += 0.1) {
    console.log(`\n*** Threshold: ${i}`);
    console.log('Clusters by String Similarity:');
    console.log(clusterUsingStringSimilarity(strings, i));

    console.log('Clusters by Fuse.js:');
    console.log(clusterUsingFuse(strings, i));

    console.log('Clusters by Assi:');
    console.log(clusterUsingAssi(strings, i));
  }
};
