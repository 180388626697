import React, { useRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { COMMENT_FORM_FIELDS_ENUM, type ICommentFormData } from '../fields/types';
import { useTranslation } from 'react-i18next';
import CommentTitle from '../fields/CommentTitle';
import SubmitButton from 'app/components/buttons/SubmitButton';
import { useRootStore } from '../../mobxStore';
import type { IFullCase, IWillNotifyInfo, IWillNotifyMentionedInfo } from '../../mobxStore/types';
import variables from 'app/utils/variables.module.scss';
import RightIcon from 'app/assets/icons/Right_icon';
import ClockIcon from 'app/assets/icons/Clock_icon';
import PeopleTwoIcon from 'app/assets/icons/PeopleTwo_icon';
import AmplService from '../../services/amplService';

interface IProps {
  willNotify: IWillNotifyInfo;
  willNotifyMentioned: IWillNotifyMentionedInfo;
  onSendMessage: () => void;
  setContainerPadding: (padding: number) => void;
}

const CommentForm = (props: IProps): React.JSX.Element => {
  const { willNotify, willNotifyMentioned, setContainerPadding } = props;
  const { reset, control, handleSubmit } = useForm<ICommentFormData>({});
  const { caseStore } = useRootStore();
  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement>(null);
  const openedCase = caseStore.openedCase as IFullCase;
  const isAddon = openedCase.basicCase.data.isAddOn;
  const hasMentionNotification = willNotifyMentioned.notify;
  const isShowNotificationsBanner = (willNotify.notify && !willNotify.isNow) || isAddon;
  const bannerText = t(isAddon ? 'notifyNowBanner' : willNotify.msgKey, willNotify.msgOptions);
  const isSubmitDisabled = caseStore.tempComment === '<p></p>' || caseStore.tempComment === '';

  useEffect(() => {
    if (!formRef.current) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      if (formRef.current) {
        setContainerPadding(formRef.current.offsetHeight);
      }
    });

    resizeObserver.observe(formRef.current);

    return function cleanup() {
      resizeObserver.disconnect();
    };
  }, [formRef.current]);

  const submitFunction = async (data: ICommentFormData): Promise<void> => {
    // clear temp comment from store on submit
    void openedCase.basicCase.commentsStore.addComment(
      caseStore.tempComment,
      caseStore.tempRawComment
    );
    caseStore.setTempComment('');
    caseStore.setTempRawComment('');

    reset({
      [COMMENT_FORM_FIELDS_ENUM.COMMENT]: ''
    });
    props.onSendMessage();
  };

  return (
    <form className="comment-form" ref={formRef}>
      <div className="notifications-banner">
        {isShowNotificationsBanner && (
          <div className="notification delievery">
            <ClockIcon stroke={variables.grey6} />
            <div className="notification-text">{bannerText}</div>
          </div>
        )}
        {hasMentionNotification && (
          <div className="notification user">
            <PeopleTwoIcon stroke={variables.grey6} />
            <div className="notification-text">
              {t(willNotifyMentioned.msgKey, willNotifyMentioned.msgOptions)}
            </div>
          </div>
        )}
      </div>
      <div
        className="input-container"
        onKeyDown={e => {
          /* Submit on Enter + Alt or Enter + Cmd */
          if (e.key === 'Enter' && (e.altKey || e.metaKey)) {
            void handleSubmit(submitFunction)();
          }
        }}
      >
        <CommentTitle control={control} />
        <SubmitButton
          id="comment-form-submit-button"
          submitFunction={async (): Promise<void> => {
            await handleSubmit(submitFunction)();
            AmplService.sendCaseEvent(AmplService.EVENTS.CHAT_SEND, openedCase.basicCase);
          }}
          content={<RightIcon fill={variables.white} height={16} />}
          classNames="submit-message-button"
          isDisabled={isSubmitDisabled}
        />
      </div>
    </form>
  );
};

export default observer(CommentForm);
