import React from 'react';
import type { IUserSelectOption } from 'app/mobxStore/types';
import { getFirstCareTeamRoleTitle } from 'app/consts';
import type { AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete';
import clsx from 'clsx';
import UserAvatar from 'app/components/userAvatar/UserAvatar';
import variables from 'app/utils/variables.module.scss';
import CheckIcon from 'app/assets/icons/Check_icon';

interface IProps {
  option: IUserSelectOption;
  state: AutocompleteRenderOptionState;
}

const PanelListItem = (props: IProps): React.JSX.Element => {
  const { option, state } = props;

  return (
    <li
      {...props}
      className={clsx('list-option', {
        selected: state.selected
      })}
    >
      <UserAvatar user={option} size={'medium'} />
      {option.label}
      <div className="user-role">{`(${getFirstCareTeamRoleTitle(option.roles)})`}</div>
      <div className="checkbox">
        <CheckIcon className="check-icon" stroke={variables.white} strokeWidth={2} />
      </div>
    </li>
  );
};

export default PanelListItem;
