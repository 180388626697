import React, { type MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'app/components/buttons/Button';
import variables from 'app/utils/variables.module.scss';
import CloseIcon from 'app/assets/icons/Close_icon';
import ArrowLeftIcon from 'app/assets/icons/ArrowLeft_icon';
import clsx from 'clsx';
import AmplService from '../../services/amplService/amplService';
import type { AutocompleteRenderInputParams } from '@mui/material/Autocomplete/Autocomplete';

interface IProps {
  params: AutocompleteRenderInputParams;
  isDirty: boolean;
  handleBack: (e: MouseEvent) => Promise<void>;
  handleApply: (e: MouseEvent) => Promise<void>;
  setIsInputFocused: (value: boolean) => void;
  isInputFocused: boolean;
  setInputValue: (value: string) => void;
  inputValue: string;
}

const PanelInput = (props: IProps): React.JSX.Element => {
  const {
    params,
    isDirty,
    handleBack,
    handleApply,
    setIsInputFocused,
    isInputFocused,
    setInputValue,
    inputValue
  } = props;
  const { t } = useTranslation();

  const handleOnClickApply = async (e: MouseEvent): Promise<void> => {
    AmplService.sendEvent(AmplService.EVENTS.FOLLOWERS_SELECT_PANEL_APPLY);
    await handleApply(e);
  };

  return (
    <div className="input-container">
      <Button
        classNames="circle-button back-button"
        startIcon={<ArrowLeftIcon height={14} stroke={variables.grey6} />}
        onClick={async (e: MouseEvent): Promise<void> => {
          AmplService.sendEvent(AmplService.EVENTS.FOLLOWERS_SELECT_PANEL_BACK);
          await handleBack(e);
        }}
      />
      <div className="input-wrapper">
        <input
          type="text"
          onClick={() => {
            setIsInputFocused(true);
          }}
          {...params.inputProps}
          className={clsx('text-input', { focused: isInputFocused })}
          placeholder={t('searchTeamMembers').toString()}
        />
        {inputValue !== '' && (
          <Button
            classNames="btn circle-button clear-button"
            startIcon={<CloseIcon height={10} stroke={variables.grey6} />}
            onClick={async (e: MouseEvent) => {
              setInputValue('');
            }}
          />
        )}
      </div>
      <Button
        classNames="btn primary done-button"
        buttonText="apply"
        onClick={async (e: MouseEvent): Promise<void> => {
          await handleOnClickApply(e);
        }}
        disabled={!isDirty}
      />
    </div>
  );
};

export default PanelInput;
