import React from 'react';
import HuddleMessage from 'app/components/emptyHuddle/HuddleMessage';
import FillCaseMetaForm from '../fillMetaForm/FillCaseMetaForm';
import { useFillMetaForm } from '../fillMetaForm/useFillMetaForm';
import { observer } from 'mobx-react-lite';

const CaseWithMessage = (): React.JSX.Element => {
  const {
    control: metaFormControl,
    allValues: metaFormAllValues,
    handleProcedureChange
  } = useFillMetaForm();

  return (
    <div className="not-ready-case">
      <FillCaseMetaForm
        handleProcedureChange={handleProcedureChange}
        showSubmit={false}
        control={metaFormControl}
        allValues={metaFormAllValues}
      />
      <HuddleMessage />
    </div>
  );
};

export default observer(CaseWithMessage);
