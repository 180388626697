import React, { useEffect, useRef, useState } from 'react';
import type {
  IChatSuggestion,
  ICommentData,
  IFullCase,
  IUserDataWithRoles
} from '../../mobxStore/types';
import { observer } from 'mobx-react-lite';
import { useRootStore } from 'app/mobxStore';
import { parseUserToSelectOption } from 'app/mobxStore/MetaDataStore';
import { useTranslation } from 'react-i18next';
import { useLongPress } from 'use-long-press';
import Button from 'app/components/buttons/Button';
import clsx from 'clsx';
import UserAvatar from 'app/components/userAvatar/UserAvatar';
import CommentMenu from './CommentMenu';
import Likes from './Likes';
import RichTextField from 'app/components/richTextField/RichTextField';
import CommentSuggestion from './CommentSuggestion';
import CheckIcon from 'app/assets/icons/Check_icon';
import CloseIcon from 'app/assets/icons/Close_icon';
import './Comments.scss';
import variables from 'app/utils/variables.module.scss';
import apiService from '../../services/apiService/apiService';
import { toast } from 'react-toastify';

interface IProps {
  comment: ICommentData;
  readByUsers?: IUserDataWithRoles[];
  isCurrentUserLastRead?: boolean;
  commentSuggestion?: IChatSuggestion;
  isProcessingSuggestions?: boolean;
  isPreviousCommentBySameUser?: boolean;
  setContainerPadding?: (padding: number) => void;
  containerPadding?: number;
  commentInEditMode?: ICommentData | null;
  setCommentInEditMode?: (comment: ICommentData | null) => void;
}

const Comment = (props: IProps): React.JSX.Element => {
  const {
    comment,
    readByUsers,
    isCurrentUserLastRead,
    commentSuggestion,
    isProcessingSuggestions,
    isPreviousCommentBySameUser,
    setContainerPadding,
    containerPadding,
    commentInEditMode,
    setCommentInEditMode
  } = props;

  const { t } = useTranslation();
  const { user, formattedComment, updatedAt, comment: commentStr } = comment;
  const message = formattedComment || commentStr;
  const [currentMessage, setCurrentMessage] = useState<string>(message);
  const { metaDataStore, userStore, caseStore } = useRootStore();
  const openedCase = caseStore.openedCase as IFullCase;
  const isSelf = userStore.loggedInUser.data.id === user.id;
  const commentTime = metaDataStore.formatDateInSiteTZ(new Date(updatedAt), 'hh:mm a');
  const mentionOptions = caseStore.openedCase?.basicCase.data.caseFollowers;
  const commentBubbleRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [bubbleInitHeight, setBubbleInitHeight] = useState<number>(0);
  const [tempMessage, setTempMessage] = useState<string>(message);
  const [rawTempMessage, setRawTempMessage] = useState<string>(message);
  const isEditing = commentInEditMode === comment;

  useEffect(() => {
    setBubbleInitHeight(commentBubbleRef.current?.offsetHeight ?? 0);
  }, []);

  const handleSetIsEditing = (isEditing: boolean): void => {
    setCommentInEditMode?.(comment);
    if (containerPadding && commentBubbleRef.current?.offsetHeight) {
      setContainerPadding?.(
        isEditing ? containerPadding + commentBubbleRef.current.offsetHeight - bubbleInitHeight : 0
      );
    }
  };

  const bindLongPress = useLongPress(
    () => {
      if (isSelf) {
        if (commentInEditMode === null) {
          setAnchorEl(commentBubbleRef.current);
        }
        return;
      }

      // !self
      toast.warning(t('youCannotEditOthersComments'));
    },
    { threshold: 600 }
  );

  const handleCancelEditComment = (): void => {
    setCurrentMessage(message);
    setCommentInEditMode?.(null);
  };

  const handleApplyEditComment = (): void => {
    void apiService.updateComment(comment.id, tempMessage, rawTempMessage);
    setCommentInEditMode?.(null);
  };

  const handleOnChangeComment = (value: string, rawValue: string): void => {
    setCurrentMessage(value);
    setTempMessage(value);
    setRawTempMessage(rawValue);
  };

  return (
    <div className={clsx('comment', { self: isSelf, editing: isEditing })}>
      <CommentMenu
        open={menuOpen}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        comment={comment}
        handleSetIsEditing={handleSetIsEditing}
      />
      {!isSelf && !isPreviousCommentBySameUser && (
        <div className="comment-user">
          <UserAvatar user={parseUserToSelectOption(user)} size={'medium'} />
          <div className="user-name">{user.nickName}</div>
        </div>
      )}
      <div className="comment-bubble" {...bindLongPress()} ref={commentBubbleRef}>
        <RichTextField
          value={currentMessage}
          mentionOptions={mentionOptions}
          readonly={!isEditing}
          focus={isEditing}
          onChange={handleOnChangeComment}
        />
        {isEditing && (
          <div className="edit-comment-actions">
            <Button
              classNames="circle-button cancel-button"
              startIcon={<CloseIcon strokeWidth={0.8} stroke={variables.white} />}
              onClick={async e => {
                handleCancelEditComment();
              }}
            />
            <Button
              classNames="circle-button apply-button"
              startIcon={<CheckIcon strokeWidth={1.2} stroke={variables.white} />}
              onClick={async e => {
                handleApplyEditComment();
              }}
            />
          </div>
        )}
        <div className="comment-time">{commentTime}</div>
        <Likes comment={comment} openedCase={openedCase} />
      </div>
      {readByUsers && readByUsers.length > 0 && (
        <div className="read-receipts">
          {readByUsers.map(follower => (
            <div key={follower.id} className="receipt">
              <UserAvatar user={parseUserToSelectOption(follower)} size={'small'} />
            </div>
          ))}
        </div>
      )}
      {commentSuggestion && (
        <CommentSuggestion
          commentSuggestion={commentSuggestion}
          isProcessingSuggestions={isProcessingSuggestions}
          commentTime={commentTime}
        />
      )}
      {isCurrentUserLastRead && (
        <div className="unread-separator">
          <div className="separator-text">{t('unreadMessages')}</div>
        </div>
      )}
    </div>
  );
};

export default observer(Comment);
