import type {
  IChatSuggestion,
  ICommentData,
  IFieldMetaData,
  IFullCase,
  IOptionMetaData
} from '../../mobxStore/types';
import { useRootStore } from '../../mobxStore';
import { useBriefForm } from '../briefForm/useBriefForm';
import { compareLetterDigitsOnly } from '../../../utils/form/simliarStrings';
import { toast } from 'react-toastify';
import apiService from '../../services/apiService';
import useNotifyCaseChanges from '../caseViews/hooks/useNotifyCaseChanges';
import { DynamicField } from '../../../utils/form/shared';
import { useEffect, useState } from 'react';

const useBotSuggestions = (): {
  suggestionComments: IChatSuggestion[];
  isProcessingSuggestions: boolean;
} => {
  const { caseStore } = useRootStore();
  const openedCase = caseStore.openedCase as IFullCase;
  const { comments } = openedCase.basicCase.data;
  const currentUser = openedCase.basicCase.store.rootStore.userStore.loggedInUser;
  const { formMetaData } = useBriefForm(false);
  const { handleNotifyOnChanges } = useNotifyCaseChanges();
  const [suggestionComments, setSuggestionComments] = useState<IChatSuggestion[]>([]);
  const [isProcessingSuggestions, setIsProcessingSuggestions] = useState<boolean>(false);
  useEffect(() => {
    setSuggestionComments(getSuggestions());
  }, []);
  useEffect(() => {
    setSuggestionComments(getSuggestions());
  }, [comments]);
  const findFields = (
    comment: ICommentData
  ): Array<{
    fieldMeta: IFieldMetaData;
    optionMeta: IOptionMetaData;
  }> => {
    const result: Array<{
      fieldMeta: IFieldMetaData;
      optionMeta: IOptionMetaData;
    }> = [];
    for (const fieldMeta of formMetaData) {
      for (const optionMeta of fieldMeta.options) {
        const words = comment.comment
          .replace('?', '')
          .replace(',', '')
          .replace('.', '')
          .split(' ')
          .map(word => word.trim())
          .filter(word => word.length > 1 && isNaN(Number(word)));
        for (const w of words) {
          if (compareLetterDigitsOnly(w, optionMeta.value)) {
            result.push({ fieldMeta, optionMeta });
          }
        }
      }
    }

    return result;
  };

  const getSuggestion = (comment: ICommentData): IChatSuggestion | undefined => {
    if (!currentUser.isAttending) {
      console.log('User is not attending');
      return undefined;
    }

    if (comment.user.id === currentUser.data.id) {
      console.log('User is the author', comment.comment);
      return undefined;
    }

    const isRead = comment.metaData.find(m => m.createdById === currentUser.data.id) !== undefined;
    if (isRead && false /* @todo remove */) {
      console.log('Comment is read', comment.comment);
      return undefined;
    }

    if (!comment.comment.includes('?', 0)) {
      console.log('Comment does not include a question', comment.comment);
      return undefined;
    }

    console.log('Finding field', comment.comment);

    const fields = findFields(comment);

    if (fields.length === 0) {
      return undefined;
    }

    const handleIntraop = async (values: string[], otherValues: string[]): Promise<void> => {
      // Filter out the value "Other" from the values array

      const filteredValues = values.filter((v: string) => v !== 'Other');
      const allValues = [...filteredValues, ...otherValues];
      const intraopImagingValue = allValues.join(', ');
      await openedCase.basicCase.updateCaseMetaDataToServer({
        intraop_text: intraopImagingValue
      });
      openedCase.basicCase.setIntraopText(intraopImagingValue);
    };
    const handleAdd = async (field: {
      fieldMeta: IFieldMetaData;
      optionMeta: IOptionMetaData;
    }): Promise<void> => {
      const fieldId = field.fieldMeta.fieldId;
      const selected = field.fieldMeta.options.filter(o => o.isSelected);
      const values = selected.filter(o => !o.isOther).map(o => o.value);
      const otherValues = selected.filter(o => o.isOther).map(o => o.value);
      field.optionMeta.isOther
        ? otherValues.push(field.optionMeta.value)
        : values.push(field.optionMeta.value);

      await apiService.addPmmValue(openedCase.basicCase.id, fieldId, values, otherValues);
      await handleNotifyOnChanges(
        openedCase.basicCase.id,
        openedCase.basicCase.data.state,
        openedCase.referencedData.caseFieldValues
      );
      if (fieldId === DynamicField.INTRAOP_IMAGING) {
        await handleIntraop(values, otherValues);
      }
    };

    const handleRemove = async (field: {
      fieldMeta: IFieldMetaData;
      optionMeta: IOptionMetaData;
    }): Promise<void> => {
      const fieldId = field.fieldMeta.fieldId;
      const selected = field.fieldMeta.options.filter(
        o => o.isSelected && o.value !== field.optionMeta.value
      );
      const values = selected.filter(o => !o.isOther).map(o => o.value);
      const otherValues = selected.filter(o => o.isOther).map(o => o.value);

      await apiService.addPmmValue(openedCase.basicCase.id, fieldId, values, otherValues);
      await handleNotifyOnChanges(
        openedCase.basicCase.id,
        openedCase.basicCase.data.state,
        openedCase.referencedData.caseFieldValues
      );
      if (fieldId === DynamicField.INTRAOP_IMAGING) {
        await handleIntraop(values, otherValues);
      }
    };
    const suggestion: IChatSuggestion = {
      commentId: comment.id,
      text: `Would you like to update the huddle?`,
      actions: fields.map(f => ({
        text: `${f.optionMeta.isSelected ? 'Remove' : 'Add'} ${f.optionMeta.value}`,

        onClick: async () => {
          setIsProcessingSuggestions(true);
          try {
            f.optionMeta.isSelected ? await handleRemove(f) : await handleAdd(f);
            await caseStore.openCase(openedCase.basicCase.id);
            caseStore.setRefreshForm(true);
            setSuggestionComments(getSuggestions());
            toast.success(
              `Huddle updated: ${f.optionMeta.isSelected ? 'removed' : 'added'} ${
                f.optionMeta.value
              }`
            );
          } catch (error) {
            toast.error('Failed to update huddle');
          } finally {
            setIsProcessingSuggestions(false);
          }
        }
      }))
    };

    return suggestion;
  };

  const getSuggestions = (): IChatSuggestion[] => {
    const result: IChatSuggestion[] = [];
    comments.forEach(comment => {
      const suggestion = getSuggestion(comment);
      if (!suggestion) {
        return;
      }
      result.push(suggestion);
    });

    return result;
  };

  return {
    suggestionComments,
    isProcessingSuggestions
  };
};

export default useBotSuggestions;
