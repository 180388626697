import React, { useEffect, useState, useRef } from 'react';
import { useRootStore } from '../../mobxStore';
import clsx from 'clsx';
import Comment from './Comment';
import HuddleMessage from 'app/components/emptyHuddle/HuddleMessage';
import { observer } from 'mobx-react-lite';
import CommentForm from './CommentForm';
import { getWillNotifyChatMessage, getWillNotifyMentioned } from 'app/utils/willNotifyHelper';
import type {
  IChatSuggestion,
  IFullCase,
  IUserDataWithRoles,
  ICommentData
} from '../../mobxStore/types';
import './Comments.scss';
import { getLastReadCommentPerUserMap } from './unreadCommentsHelper';
import useBotSuggestions from './useBotSuggestions';

const Comments = (): React.JSX.Element => {
  const { caseStore } = useRootStore();
  console.log('rendering comments');
  const { suggestionComments, isProcessingSuggestions } = useBotSuggestions();
  const openedCase = caseStore.openedCase as IFullCase;

  if (openedCase === null) {
    return <></>;
  }

  const messagesEndRef = useRef<any>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerPadding, setContainerPadding] = useState(0);
  const [lastReadComment, setLastReadComment] = useState<string>(
    openedCase.basicCase.commentsStore.lastReadCommentId
  );
  const [lastReadCommentPerUserMap, setLastReadCommentPerUserMap] = useState<
    Map<string, IUserDataWithRoles[]>
  >(new Map());
  const { comments } = openedCase.basicCase.data;
  const [localSuggestionComments, setLocalSuggestionComments] = useState<IChatSuggestion[]>([]);
  const [commentInEditMode, setCommentInEditMode] = useState<ICommentData | null>(null);

  const onSendMessage = (): void => {
    setLastReadComment(openedCase.basicCase.commentsStore.lastReadCommentId);
  };

  useEffect(() => {
    if (comments.length === 0) return;
    messagesEndRef.current?.scrollIntoView({ behavior: 'instant', block: 'start' });
    void openedCase.basicCase.commentsStore.commentsMarkAllAsRead();
  }, [comments.length, suggestionComments]);

  useEffect(() => {
    setLastReadCommentPerUserMap(getLastReadCommentPerUserMap(openedCase));
  }, [comments]);

  useEffect(() => {
    if (suggestionComments.length) {
      setLocalSuggestionComments(suggestionComments);
    }
  }, [suggestionComments]);

  const willNotify = getWillNotifyChatMessage();
  const willNotifyMentioned = getWillNotifyMentioned(caseStore.tempComment);

  return (
    <div
      className={clsx('fill-case-container comments', { hasComments: comments.length > 0 })}
      style={{ paddingBottom: containerPadding }}
      ref={containerRef}
    >
      <div
        className={clsx('comments-container', {
          isShow: (willNotify.notify && !willNotify.isNow) || willNotifyMentioned.notify
        })}
      >
        {comments.map(comment => (
          <Comment
            key={comment.id}
            comment={comment}
            readByUsers={lastReadCommentPerUserMap.get(comment.id) ?? []}
            isCurrentUserLastRead={lastReadComment === comment.id}
            commentSuggestion={
              suggestionComments.find(s => s.commentId === comment.id) ??
              localSuggestionComments.find(s => s.commentId === comment.id)
            }
            isProcessingSuggestions={isProcessingSuggestions}
            isPreviousCommentBySameUser={
              comments[comments.indexOf(comment) - 1]?.user.id === comment.user.id
            }
            setContainerPadding={setContainerPadding}
            containerPadding={containerPadding}
            commentInEditMode={commentInEditMode}
            setCommentInEditMode={setCommentInEditMode}
          />
        ))}
        <CommentForm
          willNotify={willNotify}
          willNotifyMentioned={willNotifyMentioned}
          onSendMessage={onSendMessage}
          setContainerPadding={setContainerPadding}
        />
      </div>
      {comments.length === 0 && <HuddleMessage />}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default observer(Comments);
