const CASES_DATE_MAP_KEY = 'casesDateMap';
const CLICKED_INVITE_KEY = 'clickedInvite';
const CLICKED_HUDDLE_KEY = 'clickedHuddle';
const CLICKED_SEEN_KEY = 'clickedSeen';
const CLICKED_UNFOLLOW_KEY = 'clickedUnfollow';
const CLICKED_CHAT_TAB_KEY = 'clickedChatTab';
const CLICKED_EDIT_CHIPS_KEY = 'clickedEditChips';
const ERROR_EXPIRE_TIME = 'errorExpireTime';
const LOCAL_STORAGE_DEV_MODE_KEY = 'devMode';
const LOGOUT_CLICKED_KEY = 'logoutClicked';
const WHATS_NEW_SEEN_VERSION = 'whatsNewSeenVersion';
const LOCAL_STORAGE_KEY_PN_SHOWN = 'pnPopupShownNew2';
const LOCAL_STORAGE_KEY_PN_SHOWN_DATES = 'pnPopupShownDates';
const CLICKED_VIEWERS_TOOLTIP_KEY = 'clickedViewersTooltip';
const LAST_TOOLTIP_DATE = 'lastTooltipDate';
const TIMES_SELECTED_PROCEDURE = 'timesSelectedProcedure';
const OVERRIDE_QUITE_HOURS = 'overrideQuiteHours';
const OVERRIDE_RECOMMENDATIONS = 'overrideRecommendations';
// const LAST_TOOLTIP_NAME = 'lastTooltipName';

export const isTrue = (val: string): boolean => val === 'true';

const isLocalStorageTrue = (key: string): boolean => isTrue(localStorage.getItem(key) ?? '');
const setLocalStorageBool = (key: string, value: boolean): void => {
  localStorage.setItem(key, value.toString());
};

export const setLogoutClicked = (value: boolean): void => {
  localStorage.setItem(LOGOUT_CLICKED_KEY, value.toString());
};

export const getLogoutClicked = (): boolean => {
  return localStorage.getItem(LOGOUT_CLICKED_KEY) === 'true';
};

export const getCasesDateMap = (): Record<string, Date> => {
  const value = localStorage.getItem(CASES_DATE_MAP_KEY);
  return value ? JSON.parse(value) : {};
};

export const addCaseToMap = (caseId: string, date: Date): void => {
  const casesDateMap = getCasesDateMap();
  casesDateMap[caseId] = date;
  setCasesDateMap(casesDateMap);
};

export const setCasesDateMap = (casesDateMap: Record<string, Date>): void => {
  localStorage.setItem(CASES_DATE_MAP_KEY, JSON.stringify(casesDateMap));
};

export const getCaseDate = (caseId: string): Date | undefined => {
  const casesDateMap = getCasesDateMap();

  /* Non surgeons will always have an empty map, so it's OK that they don't have the date */
  /* Surgeons will always have a value in the map if they have cases, and they must get a match */
  if (Object.keys(casesDateMap).length > 0 && casesDateMap[caseId] === undefined) {
    // console.error('Cases date map is empty');
    // throw new Error('Cases date map is empty');
  }

  return casesDateMap[caseId];
};

export const setLastWhatsNewViewed = (version: number): void => {
  localStorage.setItem(WHATS_NEW_SEEN_VERSION, version.toString());
};

export const getLastWhatsNewViewed = (): string | null => {
  return localStorage.getItem(WHATS_NEW_SEEN_VERSION);
};

export const setClickedInvite = (value: boolean): void => {
  localStorage.setItem(CLICKED_INVITE_KEY, value.toString());
};

export const getClickedInvite = (): boolean => {
  return localStorage.getItem(CLICKED_INVITE_KEY) === 'true';
};

export const setClickedHuddle = (value: boolean): void => {
  localStorage.setItem(CLICKED_HUDDLE_KEY, value.toString());
};

export const getClickedHuddle = (): boolean => {
  return localStorage.getItem(CLICKED_HUDDLE_KEY) === 'true';
};

export const setClickedSeenTooltip = (value: boolean): void => {
  localStorage.setItem(CLICKED_SEEN_KEY, value.toString());
};

export const getClickedSeenTooltip = (): boolean => {
  return localStorage.getItem(CLICKED_SEEN_KEY) === 'true';
};

export const setClickedUnfollowTooltip = (value: boolean): void => {
  localStorage.setItem(CLICKED_UNFOLLOW_KEY, value.toString());
};

export const getClickedUnfollowTooltip = (): boolean => {
  return localStorage.getItem(CLICKED_UNFOLLOW_KEY) === 'true';
};

export const setClickedChatTabTooltip = (value: boolean): void => {
  localStorage.setItem(CLICKED_CHAT_TAB_KEY, value.toString());
};

export const getClickedChatTabTooltip = (): boolean => {
  return localStorage.getItem(CLICKED_CHAT_TAB_KEY) === 'true';
};

export const setClickedEditChipsTooltip = (value: boolean): void => {
  localStorage.setItem(CLICKED_EDIT_CHIPS_KEY, value.toString());
};

export const getClickedEditChipsTooltip = (): boolean => {
  return localStorage.getItem(CLICKED_EDIT_CHIPS_KEY) === 'true';
};

export const setClickedViewersTooltip = (value: boolean): void => {
  localStorage.setItem(CLICKED_VIEWERS_TOOLTIP_KEY, value.toString());
};

export const getClickedviewersTooltip = (): boolean => {
  return localStorage.getItem(CLICKED_VIEWERS_TOOLTIP_KEY) === 'true';
};

export const setLastTooltipViewedDate = (time: Date | null): void => {
  localStorage.setItem(LAST_TOOLTIP_DATE, JSON.stringify(time));
};

export const getLastTooltipViewedDate = (): string | null => {
  return localStorage.getItem(LAST_TOOLTIP_DATE);
};

export const setIsDebugMode = (value: boolean): void => {
  setLocalStorageBool(LOCAL_STORAGE_DEV_MODE_KEY, value);
};

export const getIsDebugMode = (): boolean => {
  return isLocalStorageTrue(LOCAL_STORAGE_DEV_MODE_KEY);
};

export const setErrorExpireTime = (time: Date): void => {
  const expireTime = time.getTime() + 1000 * 60;
  localStorage.setItem(ERROR_EXPIRE_TIME, JSON.stringify(expireTime));
};

export const getErrorExpireTime = (): string | null => {
  return localStorage.getItem(ERROR_EXPIRE_TIME);
};

export const getIsShownPushPopup = (): boolean => {
  return isLocalStorageTrue(LOCAL_STORAGE_KEY_PN_SHOWN);
};

export const getIsShownPushPopupDates = (): string[] => {
  const item = localStorage.getItem(LOCAL_STORAGE_KEY_PN_SHOWN_DATES) ?? '[]';
  return JSON.parse(item);
};

export const setShownPushPopupDates = (arr: string[]): void => {
  localStorage.setItem(LOCAL_STORAGE_KEY_PN_SHOWN_DATES, JSON.stringify(arr));
};

export const getTimesSelectedProcedure = (): string | null => {
  return localStorage.getItem(TIMES_SELECTED_PROCEDURE);
};

export const setTimesSelectedProcedure = (value: number): void => {
  localStorage.setItem(TIMES_SELECTED_PROCEDURE, JSON.stringify(value));
};

export const getOverrideQuiteHours = (): boolean => {
  return isLocalStorageTrue(OVERRIDE_QUITE_HOURS);
};

export const getOverrideRecommendations = (): boolean => {
  return isLocalStorageTrue(OVERRIDE_RECOMMENDATIONS);
};
