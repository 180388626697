import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationPanel from 'app/components/confirmationPanel/ConfirmationPanel';
import { useRootStore } from 'app/mobxStore';
import { isSilent } from '../../utils/isSilent';

interface IProps {
  isShowPanel: boolean;
}

const CaseSubmitQuietHoursPanel = (props: IProps): React.JSX.Element => {
  const { isShowPanel } = props;
  const { t } = useTranslation();
  const { caseStore } = useRootStore();
  const openedCase = caseStore.openedCase;

  const handleNotifyNow = async (): Promise<void> => {
    void openedCase?.basicCase.setIsAddonToServer(true);
    openedCase?.basicCase.setIsAddonToStore(true);
    await handleClose();
  };

  const handleClose = async (): Promise<void> => {
    openedCase?.basicCase.setIsOpenQuietHoursPanel(false);
    caseStore.setIsShowAddonPanel(false);
  };

  const nextActive = isSilent().nextActive;
  let panelText = 'quietHoursPanelText';
  let buttonTextLater = 'quietHoursPanelButtonLater';
  if (nextActive?.weekday() === 6 /* Sunday */) {
    panelText = 'quietHoursPanelTextWeekend';
    buttonTextLater = 'quietHoursPanelButtonLaterWeekend';
  }

  return (
    <ConfirmationPanel
      title={t('quietHoursPanelTitle')}
      text={t(panelText)}
      buttons={[
        {
          buttonText: t('quietHoursPanelButtonNow'),
          onClick: handleNotifyNow,
          variant: 'outlined',
          closeOnClick: true,
          key: 'yes'
        },
        {
          buttonText: t(buttonTextLater),
          onClick: handleClose,
          variant: 'secondary',
          closeOnClick: true,
          key: 'no'
        }
      ]}
      isShowPanel={isShowPanel}
      setIsShowPanel={isTrue => {
        if (isTrue) {
          return;
        }
        void handleClose();
      }}
    />
  );
};

export default CaseSubmitQuietHoursPanel;
