import FillCaseMetaForm from '../fillMetaForm/FillCaseMetaForm';
import React, { useEffect } from 'react';
import { useFillMetaForm } from '../fillMetaForm/useFillMetaForm';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../mobxStore';
import type { IFullCase } from '../../mobxStore/types';
import CaseSubmitQuietHoursPanel from 'app/components/case/CaseSubmitQuietHoursPanel';
import HuddleBanner from 'app/components/huddle/HuddleBanner';
import ClockIcon from 'app/assets/icons/Clock_icon';
import variables from 'app/utils/variables.module.scss';

const HuddleFillMeta = (): React.JSX.Element => {
  const { handleProcedureChange, control, allValues } = useFillMetaForm();
  const rootStore = useRootStore();
  const { caseStore } = rootStore;
  const openedCase = caseStore.openedCase as IFullCase;
  const isAddon = openedCase.basicCase.data.isAddOn;
  useEffect(() => {
    rootStore.setCurEditMode(true);
  }, []);
  useEffect(() => {
    openedCase.basicCase.updateCaseEditLock(true);
  }, []);

  useEffect(() => {
    if (caseStore.isShowAddonPanel) {
      openedCase.basicCase.setIsOpenQuietHoursPanel(true);
    }
  }, [caseStore.isShowAddonPanel]);

  return (
    <div>
      <FillCaseMetaForm
        handleProcedureChange={handleProcedureChange}
        control={control}
        allValues={allValues}
        showSubmit={true}
      />
      <CaseSubmitQuietHoursPanel isShowPanel={openedCase.basicCase.isOpenQuietHoursPanel} />
      {isAddon && (
        <HuddleBanner
          bannerText="notifyNowBanner"
          bannerIcon={<ClockIcon stroke={variables.grey6} />}
        />
      )}
    </div>
  );
};

export default observer(HuddleFillMeta);
