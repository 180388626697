import { log } from 'debug';
import { flow, makeAutoObservable } from 'mobx';
import type { ICase, ICaseCommentsStore, ICommentData, ICommentMetaData } from './types';
import apiService from 'app/services/apiService';
import type { Subscription } from 'zen-observable-ts';
import {
  commentMetaDatasColumnReaction,
  commentMetaDatasColumnReadAt
} from '../services/apiService/apiService';

class CaseCommentsStore implements ICaseCommentsStore {
  kase: ICase;
  commentsSubscription: null | Subscription = null;

  constructor(kase: ICase) {
    makeAutoObservable(this, {});
    this.kase = kase;
  }

  addComment = flow(function* (this: ICaseCommentsStore, comment: string, rawComment: string) {
    try {
      const newComment = yield apiService.createComment(this.kase.data.id, comment, rawComment);
      this.kase.data.comments.push(newComment);
    } catch (error) {
      log(error);
    }
  });

  removeComment(comment: ICommentData): void {
    const commentIndex = this.kase.data.comments.indexOf(comment);
    this.kase.data.comments.splice(commentIndex, 1);
    const currentUserId = this.kase.store.rootStore.userStore.loggedInUser.data.id;
    void apiService.deleteComment(comment.id, currentUserId);
  }

  updateComment = flow(function* (
    this: ICaseCommentsStore,
    comment: ICommentData,
    newComment: string,
    newRawComment: string
  ) {
    try {
      yield apiService.updateComment(comment.id, newComment, newRawComment);
      comment.comment = newRawComment;
      comment.formattedComment = newComment;
    } catch (error) {
      log(error);
    }
  });

  subscribeToComments(): void {
    const sub = apiService.subscribeToCaseComments(this.kase.id, (data: ICommentData[]) => {
      this.setComments(data);
    });
    this.setCommentsSubscription(sub);
  }

  unsubscribeFromComments(): void {
    if (this.commentsSubscription === null) {
      return;
    }
    this.commentsSubscription.unsubscribe();
    this.clearCommentsSubscription();
  }

  setCommentsSubscription(subscription: Subscription): void {
    this.commentsSubscription = subscription;
  }

  clearCommentsSubscription(): void {
    this.commentsSubscription = null;
  }

  setComments(data: ICommentData[]): void {
    this.kase.data.comments = data;
  }

  getCommentsUnreadCount = (): number => {
    const currentUserId = this.kase.store.rootStore.userStore.loggedInUser.data.id;
    const unreadComments = this.kase.data.comments.filter(comment => {
      if (comment.user.id === currentUserId) {
        return false;
      }
      const curUserMetas = comment.metaData.find(mt => mt.createdById === currentUserId);
      if (!curUserMetas) {
        return true;
      }
      return curUserMetas.readAt === null;
    });
    return unreadComments.length;
  };

  commentsMarkAllAsRead = flow(function* (this: ICaseCommentsStore) {
    const currentUserId = this.kase.store.rootStore.userStore.loggedInUser.data.id;
    const metasObjsToUpsert: Array<Record<string, any>> = [];
    for (const comment of this.kase.data.comments) {
      const isExistMetaData =
        comment.metaData.find(mt => mt.createdById === currentUserId) !== undefined;
      const metadata = isExistMetaData
        ? (comment.metaData.find(mt => mt.createdById === currentUserId) as ICommentMetaData)
        : {
            user: this.kase.store.rootStore.userStore.loggedInUser.data,
            createdById: currentUserId
          };
      if (metadata?.readAt) {
        continue;
      }
      // tz: not date sensitive ok to use local tz
      metadata.readAt = new Date();
      if (!isExistMetaData) {
        comment.metaData.push(metadata);
      }
      metasObjsToUpsert.push({
        comment_id: comment.id,
        read_at: metadata.readAt
      });
    }

    yield apiService.upsertCommentMetaDatas(metasObjsToUpsert, commentMetaDatasColumnReadAt);
  });

  commentLike = flow(function* (this: ICaseCommentsStore, commentId: string, reaction: string) {
    const comment = this.kase.data.comments.find(c => c.id === commentId) as ICommentData;
    const currentUserId = this.kase.store.rootStore.userStore.loggedInUser.data.id;
    const isExistMetaData =
      comment.metaData.find(mt => mt.createdById === currentUserId) !== undefined;
    const metadata = isExistMetaData
      ? (comment.metaData.find(mt => mt.createdById === currentUserId) as ICommentMetaData)
      : {
          user: this.kase.store.rootStore.userStore.loggedInUser.data,
          createdById: currentUserId
        };
    metadata.reaction = reaction;
    if (!isExistMetaData) {
      comment.metaData.push(metadata);
    }
    yield apiService.upsertCommentMetaDatas(
      [
        {
          comment_id: comment.id,
          reaction
        }
      ],
      commentMetaDatasColumnReaction
    );
  });

  get lastReadCommentId(): string {
    const currentUserId = this.kase.store.rootStore.userStore.loggedInUser.data.id;
    for (let i = this.kase.data.comments.length - 1; i >= 0; i--) {
      const comment = this.kase.data.comments[i];
      if (comment.user.id === currentUserId) {
        if (i === this.kase.data.comments.length - 1) {
          return '';
        }
        return comment.id;
      }
      const myMetaData = comment.metaData.find(meta => meta.createdById === currentUserId);
      if (myMetaData?.readAt) {
        if (i === this.kase.data.comments.length - 1) {
          return '';
        }
        return comment.id;
      }
    }

    return '';
  }
}

export default CaseCommentsStore;
