import React, { useEffect, useState } from 'react';
import { useRootStore } from 'app/mobxStore';
import BriefForm from '../briefForm/BriefForm';
import HuddleImages from 'app/components/huddleImages/HuddleImages';
import FillCaseMetaForm from '../fillMetaForm/FillCaseMetaForm';
import Submit from '../form/Submit';
import { useFillMetaForm } from '../fillMetaForm/useFillMetaForm';
import { useBriefForm } from '../briefForm/useBriefForm';
import { observer } from 'mobx-react-lite';
import './Case.scss';
import { log } from 'debug';
import Feedback from 'app/components/nursingFeedback/Feedback';
import { toast } from 'react-toastify';
import { FORM_FIELDS_ENUM } from '../fields/types';
import CaseSubmitEmptyPanel from './CaseSubmitEmptyPanel';
import AmplService from '../../services/amplService';
import type { ICase, IFullCase } from 'app/mobxStore/types';
import ErrorMonitor from '../../services/errorMonitor/errorMonitor';
import ToggleEditModeButton from './ToggleEditModeButton';
import HuddleBanner from 'app/components/huddle/HuddleBanner';
import ClockIcon from 'app/assets/icons/Clock_icon';
import variables from 'app/utils/variables.module.scss';
import clsx from 'clsx';

const Case = (): React.JSX.Element => {
  const {
    needsBriefApproval,
    showEditMode,
    caseStore,
    careTeamDefaultsStore,
    userStore,
    canUpdate,
    hasLock,
    curEditMode
  } = useRootStore();
  const {
    control: metaFormControl,
    allValues: metaFormAllValues,
    handleProcedureChange
  } = useFillMetaForm();
  const {
    fields,
    control: briefFormControl,
    submitFunction: briefFormSubmitFunction,
    submitDefault: briefFormSubmitDefault,
    handleSubmit: briefFormHandleSubmit,
    allValues: briefFormAllValues,
    setFocus: briefFormSetFocus,
    formState: briefFormState,
    reset: briefFormReset,
    loadTemplate,
    toggleEditMode
  } = useBriefForm(showEditMode);
  useEffect(() => {
    if (!showEditMode) {
      return;
    }
    openedCase.basicCase.updateCaseEditLock(true);
  }, []);
  const openedCase = caseStore.openedCase as IFullCase;
  const editedByUser = openedCase.basicCase.data.editedByUser;
  const isAddon = openedCase.basicCase.data.isAddOn;
  const submitBrief = briefFormHandleSubmit(briefFormSubmitFunction);
  const [openConfirmPanelEmpty, setOpenConfirmPanelEmpty] = useState(false);

  const isBriefEmpty = (): boolean => {
    // iterate over all fields in briefFormAllValues and check that the length is 0
    for (const key in briefFormAllValues) {
      if (briefFormAllValues[key].length > 0) {
        return false;
      }
    }
    return true;
  };

  const savedDefaultIfNeeded = async (): Promise<void> => {
    const attending = metaFormAllValues[FORM_FIELDS_ENUM.SURGEON];
    const procedure = metaFormAllValues[FORM_FIELDS_ENUM.PROCEDURE];
    if (!attending || !procedure) {
      throw new Error('No attending or procedure');
    }
    const savedDefault = careTeamDefaultsStore.getDefault(attending.userId, procedure.value);
    if (savedDefault && Object.keys(savedDefault.values).length > 0) {
      log('Already have defaults. Not saving...');
      return;
    }
    const submitDefaults = briefFormHandleSubmit(briefFormSubmitDefault);
    await submitDefaults();
    log('Updated defaults');
  };

  const submitFunction = async (overrideEmptyBrief: boolean = false): Promise<void> => {
    try {
      if (isBriefEmpty() && !overrideEmptyBrief) {
        setOpenConfirmPanelEmpty(true);
        return;
      }

      await submitBrief();
      AmplService.sendCaseEvent(AmplService.EVENTS.CASE_APPROVE_HUDDLE, openedCase.basicCase);

      await savedDefaultIfNeeded();
      await handleClose();
      await handleSetRecentlyHuddledCase();
      await getNextCaseToOpen();
    } catch (error) {
      toast.error(`Sorry, that didn't work... 🤷. Please try again.`);
      log(error);
      ErrorMonitor.captureException(error as Error);
    }
  };

  const thisCaseId = openedCase.basicCase.id;
  const isSameCase = (c: ICase): boolean => {
    return c.id === thisCaseId;
  };
  const isNextInList = (c: ICase): boolean => {
    return (
      caseStore.sortedCases.indexOf(c) > caseStore.sortedCases.findIndex(c => c.id === thisCaseId)
    );
  };
  const isMyCase = (c: ICase): boolean => {
    return c.data.attendingId === userStore.loggedInUser.data.id;
  };
  const getNextCaseToOpen = async (): Promise<void> => {
    let caseToOpen = null;
    caseToOpen = caseStore.sortedCases.find(
      c => !isSameCase(c) && isNextInList(c) && isMyCase(c) && !c.data.isReady
    );
    if (!caseToOpen) {
      caseToOpen = caseStore.sortedCases.find(
        c => !isSameCase(c) && isMyCase(c) && !c.data.isReady
      );
    }
    if (!caseToOpen) {
      return;
    }
    caseStore.setCaseToOpenNext(caseToOpen);
  };

  const handleClose = async (): Promise<void> => {
    caseStore.setIsHuddleDialogOpen(false);
  };

  const handleSetRecentlyHuddledCase = async (): Promise<void> => {
    if (caseStore.openedCase) {
      caseStore.setRecentlyHuddledCase(caseStore.openedCase.basicCase);
    }
  };

  useEffect(() => {
    if (caseStore.refreshForm) {
      caseStore.setRefreshForm(false);
    }
  }, [caseStore.refreshForm]);

  const getSubmitButtonText = (): string => {
    const isOtherCasesToHuddle = caseStore.sortedCases.some(
      c => isMyCase(c) && !isSameCase(c) && !c.data.isReady
    );
    const buttonText = isOtherCasesToHuddle ? 'approveAndOpenNext' : 'approve';
    return buttonText;
  };

  const showSubmit = needsBriefApproval;
  const showBriefForm = openedCase.basicCase?.data.procedureId;
  const showFeedback = !userStore.loggedInUser.isAttending;
  const submitButtonText = getSubmitButtonText();
  const showToggleEditMode = canUpdate && !showEditMode;

  return (
    <div className={clsx('case-container', { isWithBanner: isAddon })}>
      <FillCaseMetaForm
        loadTemplate={loadTemplate}
        handleProcedureChange={handleProcedureChange}
        showSubmit={false}
        control={metaFormControl}
        allValues={metaFormAllValues}
        briefFormAllValues={briefFormAllValues}
        briefFormReset={briefFormReset}
      />
      {showBriefForm && (
        <>
          {!caseStore.refreshForm && (
            <BriefForm
              formState={briefFormState}
              setFocus={briefFormSetFocus}
              fields={fields}
              control={briefFormControl}
              allValues={briefFormAllValues}
              submitChange={canUpdate}
              showPatientFields
            />
          )}
          <HuddleImages />
          {showSubmit && (
            <div>
              <Submit label={submitButtonText} handleSubmit={submitFunction} withTransition />
              <CaseSubmitEmptyPanel
                isShowPanel={openConfirmPanelEmpty}
                setIsShowPanel={setOpenConfirmPanelEmpty}
                handleConfirm={() => {
                  void submitFunction(true);
                }}
              />
            </div>
          )}
        </>
      )}
      {showFeedback && <Feedback />}
      {showToggleEditMode && (
        <ToggleEditModeButton
          curEditMode={curEditMode}
          editedByUser={editedByUser}
          hasLock={hasLock}
          toggleEditMode={toggleEditMode}
        />
      )}
      {isAddon && (
        <HuddleBanner
          bannerText="notifyNowBanner"
          bannerIcon={<ClockIcon stroke={variables.grey6} />}
        />
      )}
    </div>
  );
};

export default observer(Case);
