import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const COMMENT_FULL_DATA = gql`
  fragment CommentFullData on comments {
    id
    case_id
    comment
    created_at
    updated_at
    formatted_comment
    user {
      nick_name
      id
      avatar
      first_name
      last_name
      roles {
        role
      }
    }
    meta_data {
      created_by_id
      read_at
      reaction
      user {
        nick_name
        id
        avatar
        first_name
        last_name
        roles {
          role
        }
      }
    }
  }
`;
