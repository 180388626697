import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  bannerText: string;
  bannerIcon?: React.JSX.Element;
}

const HuddleBanner = (props: IProps): React.JSX.Element => {
  const { bannerText, bannerIcon } = props;
  const { t } = useTranslation();
  return (
    <div className="huddle-banner">
      {bannerIcon}
      <div className="banner-text">{t(bannerText)}</div>
    </div>
  );
};

export default HuddleBanner;
